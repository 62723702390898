import { useState } from "react";
import CustomEditor from "../../../../components/Editors/CustomEditor";
import { capitalizeFirstLetter } from "../../../../utils/helpers"
import ProductThunk from "../../../../redux/thunk/ProductThunk";
import { useDispatch, useSelector } from "react-redux";

export default function EditProductModal({
    val,
    Active,
    getData,
    isAssetPage,
    data,
    states,
    setStates,
    attributesMap
}) {
    const { productDetail } = useSelector((state) => state.products);
    const [isActive, setIsActive] = useState(data.name === 'assets' ? true : Active);
    const [fileList, setFileList] = useState([]);
    const dispatch = useDispatch();


    /**
     * Asset Page Handle Upload
     * @param info
     * @param currentAttributeName
     * @param productId
     * @returns {Promise<void>}
     */
    const handleAssetUpload = async (info, currentAttributeName, productId) => {
        setFileList(info.fileList);
        const formData = new FormData();
        // // Append other fields to the FormData if needed
        formData.append('attributeName', currentAttributeName);
        formData.append('productId', productId);
        info.fileList.forEach(file => {
            formData.append('file', file.originFileObj);
        });

        await dispatch(ProductThunk.productAssetUpload(formData));
        getData();
        setFileList([])
    };

    /**
     * Function to render Image before uploading
     * @param file
     * @returns {boolean}
     */
    const beforeUpload = (file) => {
        // Read the file using FileReader and set it in the state
        const reader = new FileReader();
        reader.onloadend = () => {
            // Create a new object with the file, preview URL, and name
            const newFile = {
                uid: file.uid, name: file.name, status: 'done', url: reader.result, type: file.type
            };
            setFileList([newFile]);
        };

        reader.readAsDataURL(file);
        return false;
    };

    /**
     * Handle Change function for Attributre Value
     * @param value
     * @param type
     */
    const handleChange = (value) => {
        let type = val?.type;
        if (type === "HTML" && !isActive) {
            return;
        } else {
            if (value === '' || (type === 'number' && isNaN(value))) value = undefined;

            if ((productDetail[data.name] === value) || (type === 'multiselect' && (productDetail[data.name] && value?.every(val => productDetail[data.name]?.includes(val)) && value.length === productDetail[data.name]?.length))) {
                let temp = [...states.body];
                let ind = temp.findIndex(val => val.name === data.name);
                if (ind !== -1) {
                    temp.splice(ind, 1);
                }
                setStates(prevState => ({ ...prevState, body: temp }));
                // temp.length ? setSubmitEnable(true) : setSubmitEnable(false);
                temp.length ? setStates(prevState => ({ ...prevState, enableSubmit: true })) : setStates(prevState => ({ ...prevState, enableSubmit: false }));
            } else {
                let temp = [...states.body];
                let ind = temp.findIndex(val => val.name === data.name);
                if (ind !== -1) {
                    temp[ind].value = val?.type ? val.type === 'number' ? parseFloat(value) : val.type === 'decimal' ? parseFloat(value) : value !== undefined && value !== null ? value : '' : value !== undefined && value !== null ? value : '';
                } else {
                    temp.push({
                        name: data.name,
                        value: val?.type ? val.type === 'number' ? parseFloat(value) : val.type === 'decimal' ? parseFloat(value) : value !== undefined && value !== null ? value : '' : value !== undefined && value !== null ? value : ''
                    });
                }

                setStates(prevState => ({ ...prevState, body:temp }));
                // console.log(temp);
                setStates(prevState => ({ ...prevState, enableSubmit: true }))
            }
        }

    };

    /**
     * Function to call Custom Editor
     * @returns {JSX.Element}
     */
    const renderEditor = () => {
        let bodyValue = states.body?.find((val) => val.name === data.name);
        let productValue = (productDetail[data.name] || productDetail[data.name] === 0 || productDetail[data.name] === false) ? productDetail[data.name] : null;
        if ((bodyValue && bodyValue.value) || productValue) {
            // setIsActive(true);
        }
        console.log("bodyValues",bodyValue);
        return (<CustomEditor
            type={val?.type}
            value={(bodyValue || bodyValue === false || bodyValue === 0) ? bodyValue.value : productValue}
            onChange={handleChange}
            options={val?.selectOption ? val.selectOption : undefined}
            handleAssetUpload={handleAssetUpload}
            beforeUpload={beforeUpload}
            fileList={fileList}
            setFileList={setFileList}
            currentAttributeName={val?.name}
            productId={productDetail?.id}
            getProductDetail={getData}
        />);
    };

    return (

        (((isAssetPage && val?.type === 'media') || (!isAssetPage && val?.type !== 'media')) && (data.nameToShow !== 'thumbnail' && data.nameToShow !== "Thumbnail")) ?
            <div className={isActive ? "attribute_card active" : "attribute_card"} style={{ width: '100%', }}>
                <div className={"attribute_head"} onClick={() => setIsActive(!isActive)}>
                    <div className={"head_left_sec"}>
                        <div className={"check_icon"}>
                            <img src={"/images/check_icon.svg"} alt={""} />
                        </div>
                        <span className={"edit_icon"}>
                            <img src={"/images/edit_icon.svg"} alt={""} />
                        </span>
                        {data.nameToShow}
                    </div>
                    <div className={"head_right_sec"}>
                        <p className={"attribute_name"}>{capitalizeFirstLetter(val?.type)}</p>
                    </div>
                </div>
                <div className="attribute_body" style={{ display: isActive ? "block" : 'none', width: '100%' }}>
                    {renderEditor()}
                </div>
            </div> : <></>);
}
