// StaffForm.js
import TextInput from "../Input/TextInput";
import {Button, Input, Select, Switch} from "antd";
import PhoneInput from 'react-phone-input-2';
import {useSelector} from 'react-redux';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import CustomEditor from "../Editors/CustomEditor";

const {Option} = Select;

const StaffForm = ({
                       selectedStaff,
                       onChange,
                       type,
                       setSelectedStaff,
                       fields,
                       setFields,
                       handleAttruteAddField,
                       handleAttributeInputChange,
                       handleAttributeDeleteField,
                       setDefaultView,
                   }) => {
    const {attributes} = useSelector((state) => state.attributes);
    // const {views} = useSelector(state => state.view);
    const [val, setVal] = useState(null);
    const selectOnChange = (value, index) => {
        const updatedFields = [...fields];
        let data = {...updatedFields[index]};

        let temp = attributes.find(val => val.nameToShow === value);
        setVal(temp);
        data.attributeName = temp.name;
        data.attributeValue = null;
        updatedFields[index] = data;
        setFields(updatedFields);
        selectedStaff = {...selectedStaff, productScope: updatedFields};
        setSelectedStaff(selectedStaff);
    }
    let filteredAttributes
    if (type === 'edit') {

        const selectedAttributeNames = fields.map((field) => field.attributeName)
        const custom = attributes.filter(val => val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku');
        const notCustom = attributes.filter(val => !(val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku'))
        let temp = [...custom, ...notCustom];
        filteredAttributes = temp.filter(
            (attr) => !selectedAttributeNames.includes(attr.name)
        );
    }

    return <div className={"form_fields"}>
        <div className={"custom_form_area"}>
            <TextInput label="Name" value={selectedStaff?.name}
                       onChange={(name) => onChange({...selectedStaff, name})}/>

            <div className={"form_group"}>
                {
                    type === 'edit' ? <Input label="Email" type="email" value={selectedStaff?.email}
                            // readOnly
                                             disabled
                                             onChange={(email) => onChange({...selectedStaff, email})}/> :
                        <TextInput label="Email" type="email" value={selectedStaff?.email}
                                   onChange={(email) => onChange({...selectedStaff, email})}/>
                }
            </div>


            {type === 'add' && (
                <div className={"form_group"}>
                    <TextInput label="Password" type="password" value={selectedStaff?.password}
                               onChange={(password) => onChange({...selectedStaff, password})}/>
                </div>
            )}

            <div className={"form_group"}>
                <label>Phone Number:</label>
                <PhoneInput
                    style={{
                        "width": "100%"
                    }}
                    type="text"
                    country='us'
                    value={selectedStaff?.contactNumber}
                    onChange={(value) => onChange({...selectedStaff, contactNumber: value})}
                />

            </div>
            <div className={"form_group"}>
                <label>Role: </label>
                <Select
                    style={{width: 150}}
                    placeholder="Select Role"
                    value={selectedStaff?.roleType}
                    className={"single_select w-100"}
                    onChange={(value) => onChange({...selectedStaff, roleType: value})}
                >
                    <Option value="staff">Staff</Option>
                    <Option value="admin">Admin</Option>
                </Select>
            </div>

            <div className={"form_group switch_toggle"}>
                <label>Is Active: </label>
                <Switch
                    value={false}
                    checked={selectedStaff?.status}
                    onChange={(checked) => onChange({...selectedStaff, status: checked})}
                />
            </div>
            {type === 'edit' && <div className={"form_group"}>
                <label>Product Scope: </label>
                {fields.map((field, index) => {
                    let temp = attributes.find(attr => attr.name === field.attributeName);
                    return <div key={index}>
                        <div className={'product_scope_wraper'} align={'middle'} justify={'space-evenly'}>
                            <div className={"form_group attribute_Select_wrapper"}>
                                <Select
                                    id={`selectField${index}`}
                                    placeholder="Select or Search Attribute"
                                    value={field.attributeName === '' ? undefined : temp?.nameToShow}
                                    onChange={(value) => {
                                        selectOnChange(value, index);
                                    }}
                                    style={{width: 200}}
                                    showSearch={true}
                                    className={"attribute_select"}
                                    popupClassName={"filter_dropdown"}
                                    filterOption={(input, option) => {
                                        return option.value.toString().toLowerCase().includes(input.toLowerCase())
                                    }
                                    }
                                >
                                    {filteredAttributes.map((val, ind) => (
                                        val?.type !== 'media' ?
                                            <Option key={ind} value={val.nameToShow}>
                                                <div className={"filter_dropdown_value"}>
                                                    <div className={"name_sec"}>
                                                        {val.nameToShow}
                                                    </div>
                                                    <div className={"select_type"}>
                                                        {val?.type.toLowerCase()}
                                                    </div>
                                                </div>
                                            </Option> : <></>
                                    ))}
                                </Select>

                            </div>
                            <div className={"form_group"}>
                                {field?.attributeName && field.attributeName !== '' ? <CustomEditor
                                    type={field.attributeName === val?.name ? val?.type === 'dropdown' ? 'multiselect' : val?.type : temp?.type === 'dropdown' ? 'multiselect' : temp?.type}
                                    value={field.attributeValue}
                                    options={val?.selectOption ? val?.selectOption : temp?.selectOption}
                                    isProductScope={true}
                                    onChange={(e) => handleAttributeInputChange(index, val?.type === 'number' ? parseFloat(e) : val?.type === 'decimal' ? parseFloat(e) : e)}
                                /> : <Input
                                    id={`inputFieldValue${index}`}
                                    placeholder="Enter value"
                                    value={field.attributeValue}
                                    rootClassName={"custom-input-field"}
                                    onChange={(e) => handleAttributeInputChange(index, e.target.value)}
                                />}

                            </div>
                            <div className={"form_group delete_wraper"}>
                                <Button onClick={() => handleAttributeDeleteField(index)}><DeleteOutlined/></Button>

                            </div>
                        </div>
                    </div>
                })}
                <Button className={"secondary_btn"} onClick={() => {
                    handleAttruteAddField()
                }}><PlusOutlined/> Add</Button>
            </div>}

            {/*{type === 'edit' && <div className={"form_group"}>*/}
            {/*    <Form.Item label={"Default View"}>*/}
            {/*        <Select className={"single_select"} placeholder={"Select a default View"}*/}
            {/*                value={selectedStaff.defaultViewName}*/}
            {/*                onSelect={val => {*/}
            {/*                    setSelectedStaff({...selectedStaff, defaultViewName: views[val]?.name});*/}
            {/*                    if (val || val === 0) setDefaultView(views[val]);*/}
            {/*                }}*/}
            {/*        >*/}
            {/*            <Select.Option value="" key={"null-option"}>*/}
            {/*                /!*{"Null"}*!/*/}
            {/*            </Select.Option>*/}
            {/*            {views.map((val, index) =>*/}
            {/*                <Select.Option*/}
            {/*                    value={index}*/}
            {/*                    key={index}*/}
            {/*                >*/}
            {/*                    {val?.name}*/}
            {/*                </Select.Option>*/}
            {/*            )}*/}
            {/*        </Select>*/}
            {/*    </Form.Item>*/}

            {/*</div>}*/}

        </div>
    </div>
};

export default StaffForm;
