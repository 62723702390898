import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Input, Modal, Row, Space, Spin, Table, Tag} from 'antd';
import {fetchStaffList, createStaff, editStaff, deleteStaff, resetPasswordStaff} from '../../../redux/thunk/StaffThunk';
import AttributeThunk from '../../../redux/thunk/AttributeThunk';
import Toast from '../../../components/Notification/Toast';
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import StaffForm from "../../../components/Form/StaffForm";
import StaffModal from "../../../components/Modal/StaffModal";
import dayjs from "dayjs";
import {CREATE_STAFF_FULFILLED,EDIT_STAFF_FULFILLED,DELETE_STAFF_FULFILLED,RESET_PASSWORD_FULFILLED} from "../../../redux/types/StaffActionTypes"
import {toProperCase} from "../../../utils/helpers";
import ViewThunk from "../../../redux/thunk/ViewThunk";
const StaffList = () => {
    const dispatch = useDispatch();
    const {staffList, error, isLoading, count} = useSelector((state) => state.staff);
    const {user} = useSelector((state) => state.user);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [sortedColumn, setSortedColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [initialRender, setInitialRender] = useState(true);
    const [fields, setFields] = useState([]);
    const [page, setPage] = useState(1);
    const [defaultView,setDefaultView]=useState(null);
    const [modalVisibleProductScope, setModalVisibleProductScope] = useState(false);
    const [modalData, setModalData] = useState([]);
    // const {attributes}=useSelector(state=>state.attributes);

    // const showModalProductScope = (data) => {
    //     setModalData(data);
    //     setModalVisibleProductScope(true);
    // };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const handleModalCancelProductScope = () => {
        setModalVisibleProductScope(false);
    };
    useEffect(() => {
        const phoneInput = document.querySelector(".form-control");
        if (phoneInput) {
            phoneInput.style.width = "100%";
        }
    }, [])

    useEffect(() => {
        // dispatch(AttributeThunk.fetchAttributes());
        handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortedColumn, sortOrder])

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            return;
        }
        const abortController = new AbortController();

        const timer = setTimeout(() => {
            handleSearch();
        }, 1000);
        return () => {
            clearTimeout(timer);
            abortController.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal]);


    const handleAddStaff = () => {

        setModalType('')
        setSelectedStaff(null);
        setModalVisible(true);
        setModalType('add');
        setModalTitle('Add Staff');
    };
    const showEditModal = (staff) => {
        dispatch(AttributeThunk.fetchAttributes());
        dispatch(ViewThunk.listView());
        setModalType('')
        setSelectedStaff(staff);
        setModalType('edit');
        setModalTitle('Edit Staff');
        setFields(staff.productScope)
        setModalVisible(true);
    };

    const showDeleteModal = (staff) => {
        setModalType('')
        setSelectedStaff(staff);
        setModalType('delete')
        setModalTitle('Delete Staff');
        setModalVisible(true);
    };
    const resetPasswordModel = (staff) => {
        setModalType('')
        setSelectedStaff(staff);
        setModalVisible(true)
        setModalType('resetPassword');
        setModalTitle('Reset Password');
    }

    const handleAddSubmit = async () => {
        // Dispatch the addStaff action with selectedStaff

        const payload = await dispatch(createStaff({...selectedStaff,status:selectedStaff?.status?selectedStaff.status:false}));
        if (payload.type === CREATE_STAFF_FULFILLED) {
            setModalVisible(false);
            Toast.successNotification('Staff Added Successfully')
            dispatch(fetchStaffList());
        } else {
            Toast.errorNotification(payload?.error?.message ? payload?.error?.message : error)
        }

    };

    const handleSearch = () => {
        let body = {
            "search": isSearching ? searchVal : '',
            "skip": Math.round((page - 1) * 10),
            "limit": 10
        }
        if (sortedColumn) {
            body = {
                ...body, "order": [{
                    "columnName": sortedColumn,
                    "direction": sortOrder === 'ascend' ? false : true
                }],
            }
        }
        dispatch(fetchStaffList(body));
    }
    const handleSort = (columnKey) => {
        if (columnKey === sortedColumn) {
            setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend');
        } else {
            setSortedColumn(columnKey);
            setSortOrder('ascend');
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination);
    }
    const handleSearchVal = (val) => {
        setSearchVal(val.target.value)
        if (val.target.value === '') {
            setIsSearching(false)
        } else {
            if (!isSearching) setIsSearching(true);
        }
    }


    const handleEditSubmit = async (editedStaff) => {
        if(!selectedStaff) return

        // user cant disable himself status
        if (user && user.staff && user.staff.id === selectedStaff.id && !editedStaff.status) {
            Toast.errorNotification('You cannot disable yourself')
            return
        }
        let editedStaffWithoutType={...editedStaff};
        if(editedStaffWithoutType.productScope){
            editedStaffWithoutType.productScope=editedStaffWithoutType?.productScope.filter(val=>{
               if(val.attributeName && ((val.attributeValue || val.attributeValue===false))) {
                   if(Array.isArray(val.attributeValue) )return val.attributeValue?.length;
                   else return true;
               }
               return false;
            });
            editedStaffWithoutType.productScope=[...editedStaffWithoutType?.productScope?.map(val=>{
                let temp={...val}
                if(temp.type){
                    delete temp?.type;
                }
                return temp;
            })];
        }

        const payload = await dispatch(editStaff(editedStaffWithoutType));

        if (payload.type === EDIT_STAFF_FULFILLED) {
            if(defaultView){
                const body={
                    "id":defaultView?.id.toString(),
                    "userId":selectedStaff.id
                };
                dispatch(ViewThunk.setDefaultView(body));
            }
            Toast.successNotification('Staff Edited Successfully')
            setModalVisible(false);
            setSelectedStaff(null);
            setFields([]);
        } else {

            Toast.errorNotification(payload?.error?.message)
        }
    };

    const handleDeleteConfirm = async () => {
        if(!selectedStaff) return
        // user cant delete himself
        if (user && user.staff && user.staff.id === selectedStaff.id) {
            Toast.errorNotification('You cannot delete yourself')
            return
        }

        const payload = await dispatch(deleteStaff(selectedStaff.id));
        if (payload.type === DELETE_STAFF_FULFILLED) {
            Toast.successNotification('Staff Deleted Successfully')
            setModalVisible(false);
            setSelectedStaff(null);
        } else {
            Toast.errorNotification(payload?.error?.message)
        }

    };


    const handleResetPassword = async () => {
        if(selectedStaff && selectedStaff.password && selectedStaff?.password!==selectedStaff?.confirmPassword ){
            Toast.errorNotification("Password doesn't match!");
            return;
        }
        if ( selectedStaff.password.length <4){
            Toast.errorNotification("Password must be greater that 4 characters");
            return;
        }
        const obj = {
            id: selectedStaff.id,
            password: selectedStaff.password
        }
        const payload = await dispatch(resetPasswordStaff(obj));
        if (payload.type === RESET_PASSWORD_FULFILLED) {
            Toast.successNotification('Password Reset Successfully');
            setModalVisible(false);
            setSelectedStaff(null);
        } else {
            Toast.errorNotification(payload?.error?.message ? payload?.error?.message : error)
        }
    }

    let columns = [
        {
            title: (
                <Row justify={'space-between'} align={'middle'} onClick={() => handleSort('name')}>
                    <Col>Name</Col>
                    <Col>
                        {(sortedColumn === 'name' || sortedColumn === '') &&
                            (sortOrder === 'ascend' ? <ArrowUpOutlined/> : <ArrowDownOutlined/>)}
                    </Col>
                </Row>
            ),
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: (
                <Row justify={'space-between'} align={'middle'} onClick={() => handleSort('roleType')}>
                    <Col>Role Type</Col>
                    <Col>
                        {(sortedColumn === 'roleType') &&
                            (sortOrder === 'ascend' ? <ArrowUpOutlined/> : <ArrowDownOutlined/>)}
                    </Col>
                </Row>
            ),
            dataIndex: 'roleType',
            key: 'roleType',
            width: 200,
            render:(text)=>{
                return <p>{text==='admin'?"Admin":"Staff"}</p>
        }

        },
        {
            title: <Row justify={'space-between'} align={'middle'} onClick={() => handleSort('status')}>
                <Col>Status</Col>
                <Col>
                    {(sortedColumn === 'status') &&
                        (sortOrder === 'ascend' ? <ArrowUpOutlined/> : <ArrowDownOutlined/>)}
                </Col>
            </Row>,
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status) => {
                if (status) {
                    return 'Active';
                } else {
                    return 'Inactive';
                }
            },
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            width: 200,
        },
        {
            title: "Product Scope",
            dataIndex: "productScope",
            key: "productScope",
            render: (productScope) => (

                <div className="tags custom_table_tags">
                    {productScope!==null && productScope!==undefined && productScope.slice(0, 2).map((option, index) => (
                        <div key={index}>
                            {Array.isArray(option.attributeValue) &&  option.attributeValue.length ? (
                                <Tag color="blue">
                                    {toProperCase(option.attributeName)}

                                </Tag>
                            ) : (
                                <Tag color={index % 2 === 0 ? "blue" : "blue"}>
                                    {toProperCase(option.attributeName)}

                                </Tag>
                            )}
                        </div>
                    ))}

                    {/*{productScope && productScope.length > 2 && (*/}
                    {/*    <div className={"more_items"}>*/}
                    {/*        <Tooltip title="Show more options">*/}
                    {/*            <span onClick={() => showModalProductScope(productScope)}>Show {productScope.length-2} More...</span>*/}
                    {/*        </Tooltip>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>


            )
        },
        // {
        //     key: "Default View",
        //     title: "Default View",
        //     dataIndex: "defaultViewName",
        //     render:(text)=><Tag color={'blue'}>{text}</Tag>
        // },
        {
            key: 'Updated At',
            title: 'Updated At',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <p>{dayjs(updatedAt).format('MMM D, YYYY')}</p>
            )
        },
        {
            title: 'Actions',
            key: 'actions',

            render: (text, record) => (
                <div>
                    {user && user.staff && user.staff.roleType === 'admin' && (

                        <Space>
                            <Button className={"paddingLeft-0 icon_text_btn"} type="secondary" onClick={() => showEditModal(record)}>
                                <span className={"icon"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M17.3047 6.81991C18.281 5.8436 18.281 4.26069 17.3047 3.28438L16.7155 2.69512C15.7391 1.71881 14.1562 1.71881 13.1799 2.69512L3.69097 12.1841C3.34624 12.5288 3.10982 12.9668 3.01082 13.4442L2.34111 16.6735C2.21932 17.2607 2.73906 17.7805 3.32629 17.6587L6.55565 16.989C7.03302 16.89 7.47103 16.6536 7.81577 16.3089L17.3047 6.81991ZM16.1262 4.46289L15.5369 3.87363C15.2115 3.5482 14.6839 3.5482 14.3584 3.87363L13.4745 4.75755L15.2423 6.52531L16.1262 5.6414C16.4516 5.31596 16.4516 4.78833 16.1262 4.46289ZM14.0638 7.70382L12.296 5.93606L4.86948 13.3626C4.75457 13.4775 4.67577 13.6235 4.64277 13.7826L4.23082 15.769L6.21721 15.3571C6.37634 15.3241 6.52234 15.2453 6.63726 15.1303L14.0638 7.70382Z" fill="#667085"/>
                                    </svg>
                                </span>Edit
                            </Button>

                            {
                                user && user.staff && user.staff.id === record.id ? null :  <Button className={"icon_text_btn"} type="secondary" onClick={() => showDeleteModal(record)} >
                                   <span className={"icon"}>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M8.33317 8.12484C8.79341 8.12484 9.1665 8.49793 9.1665 8.95817V13.9582C9.1665 14.4184 8.79341 14.7915 8.33317 14.7915C7.87293 14.7915 7.49984 14.4184 7.49984 13.9582V8.95817C7.49984 8.49793 7.87293 8.12484 8.33317 8.12484Z" fill="#667085"/>
                                        <path d="M12.4998 8.95817C12.4998 8.49793 12.1267 8.12484 11.6665 8.12484C11.2063 8.12484 10.8332 8.49793 10.8332 8.95817V13.9582C10.8332 14.4184 11.2063 14.7915 11.6665 14.7915C12.1267 14.7915 12.4998 14.4184 12.4998 13.9582V8.95817Z" fill="#667085"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.9998 4.99984V4.1665C14.9998 2.78579 13.8806 1.6665 12.4998 1.6665H7.49984C6.11913 1.6665 4.99984 2.78579 4.99984 4.1665V4.99984H3.74984C3.2896 4.99984 2.9165 5.37293 2.9165 5.83317C2.9165 6.29341 3.2896 6.6665 3.74984 6.6665H4.1665V15.8332C4.1665 17.2139 5.28579 18.3332 6.6665 18.3332H13.3332C14.7139 18.3332 15.8332 17.2139 15.8332 15.8332V6.6665H16.2498C16.7101 6.6665 17.0832 6.29341 17.0832 5.83317C17.0832 5.37293 16.7101 4.99984 16.2498 4.99984H14.9998ZM12.4998 3.33317H7.49984C7.0396 3.33317 6.6665 3.70627 6.6665 4.1665V4.99984H13.3332V4.1665C13.3332 3.70627 12.9601 3.33317 12.4998 3.33317ZM14.1665 6.6665H5.83317V15.8332C5.83317 16.2934 6.20627 16.6665 6.6665 16.6665H13.3332C13.7934 16.6665 14.1665 16.2934 14.1665 15.8332V6.6665Z" fill="#667085"/>
                                        </svg>
                                   </span> Delete
                                </Button>
                            }

                            <Button className={"icon_text_btn"} type="secondary" onClick={() => resetPasswordModel(record)}>
                              <span className={"icon"}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M14.1667 8.33335V5.83335C14.1667 4.72828 13.7277 3.66848 12.9463 2.88708C12.1649 2.10567 11.1051 1.66669 9.99999 1.66669C8.89492 1.66669 7.83511 2.10567 7.05371 2.88708C6.27231 3.66848 5.83332 4.72828 5.83332 5.83335C5.83332 6.29169 6.20832 6.66669 6.66666 6.66669C7.12499 6.66669 7.49999 6.29169 7.49999 5.83335C7.49999 5.17031 7.76338 4.53443 8.23222 4.06559C8.70106 3.59675 9.33695 3.33335 9.99999 3.33335C10.663 3.33335 11.2989 3.59675 11.7678 4.06559C12.2366 4.53443 12.5 5.17031 12.5 5.83335V8.33335H4.99999C4.55796 8.33335 4.13404 8.50895 3.82148 8.82151C3.50892 9.13407 3.33332 9.55799 3.33332 10V16.6667C3.33332 17.1087 3.50892 17.5326 3.82148 17.8452C4.13404 18.1578 4.55796 18.3334 4.99999 18.3334H15C15.442 18.3334 15.8659 18.1578 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10C16.6667 9.55799 16.4911 9.13407 16.1785 8.82151C15.8659 8.50895 15.442 8.33335 15 8.33335H14.1667ZM15 10V16.6667H4.99999V10H15ZM8.33332 11.6667H6.66666V15H8.33332V11.6667Z" fill="#667085"/>
                                    </svg>
                              </span>  Reset Password
                            </Button>
                        </Space>
                    )}
                </div>
            ),
        },
    ];
    if (user && user.staff && user.staff.roleType === 'staff') {
        columns = columns.filter((column) => column.key !== 'actions');
    }
    const handleModalSubmit = async () => {
        switch (modalType) {
            case 'add':
                if(selectedStaff.email && !validateEmail(selectedStaff.email)){
                        Toast.errorNotification("Enter a Valid Email!");
                        break;
                }
                await handleAddSubmit();
                break;
            case 'edit':
                await handleEditSubmit(selectedStaff);
                break;
            case 'delete':
                await handleDeleteConfirm();
                break;
            case 'resetPassword':
                await handleResetPassword();
                break;
            default:
            // Handle other cases if needed
        }
    };
    const handleModalCancel = () => {
        setModalVisible(false);
        setSelectedStaff(null);
        setModalType(null);
        setModalTitle(null);
        setFields([]);

    }

    const handleAttruteAddField = () => {
        const newFields = [...fields, {attributeName: undefined, attributeValue: undefined}];
        setFields(newFields);
    };
    const handleAttributeInputChange = (index, value) => {
        let updatedFields = [...fields];
        let tempFiles={...updatedFields[index]};
        tempFiles.attributeValue=value;
        updatedFields[index]=tempFiles;
        setFields(updatedFields);
        let tempSelectedStaff = { ...selectedStaff, productScope: updatedFields };
         setSelectedStaff(tempSelectedStaff);
    };

    const handleAttributeDeleteField = (index) => {
        let updatedFields = [...fields];
        updatedFields.splice(index, 1);
        setFields(updatedFields);
        let tempSelectedStaff = { ...selectedStaff, productScope: updatedFields };
         setSelectedStaff(tempSelectedStaff);

    };
    const renderContent = () => {
        switch (modalType) {
            case 'add':
                return <StaffForm selectedStaff={selectedStaff} onChange={setSelectedStaff} type="add"/>;
            case 'edit':
                return <StaffForm fields={fields}
                                  setDefaultView={setDefaultView}
                                  setFields={setFields}
                                  setSelectedStaff={setSelectedStaff}
                                  selectedStaff={selectedStaff}
                                  onChange={setSelectedStaff}
                                  handleAttributeInputChange={handleAttributeInputChange}
                                  handleAttruteAddField={handleAttruteAddField}
                                  handleAttributeDeleteField={handleAttributeDeleteField}
                                  type="edit"/>;
            case 'delete':
                return <p className={"delete_message"} style={{fontSize:'20px'}}>Are you sure you want to delete this staff?</p>;
            case 'resetPassword':
                return (
                    <div>
                        <div className={"form_group"}>
                            <label>Enter New Password</label>
                            <Input
                                rootClassName={"custom-input-field"}
                                type="text"
                                value={selectedStaff?.password}
                                onChange={(e) => setSelectedStaff({...selectedStaff, password: e.target.value})}
                            />

                        </div>
                        <div className={"form_group"} style={{marginTop: "10px"}}>
                            <label>Confirm Password</label>
                            <Input
                                rootClassName={"custom-input-field"}
                                type="text"
                                value={selectedStaff?.confirmPassword}
                                onChange={(e) => setSelectedStaff({...selectedStaff, confirmPassword: e.target.value})}
                            />

                        </div>
                    </div>
                )
                    ;
            default:
                return null;
        }
    };

    const fixedTop = false;
    return (
        <div className="">
            <Modal
                title="Product Scope"
                open={modalVisibleProductScope}
                onCancel={handleModalCancelProductScope}
                footer={null}
                className={"custom_modal w-600"}

            >
                <div className="tags custom_table_tags">
                    {modalData.map((val, index) => (

                        <div key={index}>
                            <Tag color="blue">
                                {val.attributeName} : {val.attributeValue}
                            </Tag>
                        </div>
                    ))}
                </div>
            </Modal>


            <div
                className={"right_inner_wrapper"}>
            <div className={"right_inner_header"}>
                <p className="page_title">Staff List</p>
                <div className="right_btn_wrap">
                    <div className="search_box">
                        <Input
                            type="text"
                            placeholder='Search'
                            value={searchVal}
                            onChange={handleSearchVal}
                        />
                        <span className="search_icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z" stroke="#969696" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                    </div>



                    <div className="filter_wrapper" style={{marginLeft:  '20px'}} >
                        {user && user.staff && user.staff.roleType === 'admin' && (
                            <Button className="primary_btn" type="primary" onClick={handleAddStaff}>
                                Add Staff
                            </Button>
                        )}

                        <StaffModal
                            visible={modalVisible}
                            onCancel={handleModalCancel}
                            onSubmit={handleModalSubmit}
                            title={modalTitle}
                            content={renderContent()}
                        />
                    </div>
                </div>
                </div>
            <div>
                <div className="custom_table_ui">
                    <Spin spinning={isLoading}>
                        <Table className={"staff_list_table"} dataSource={staffList} columns={columns} pagination={{
                            current: page,
                            pageSize: 10,
                            total: count,
                            onChange: handleTableChange,

                        }}
                               fixed={fixedTop ? 'top' : 'bottom'}
                               showHeader={true}
                               scroll={{
                                   x:2500,
                               }}
                               sticky={{
                                   offsetHeader: 0,
                               }}


                               rowKey={'id'}
                        />
                    </Spin>
                </div>
            </div>
            </div>
        </div>


    );
};

export default StaffList;
