export const FETCH_ATTRIBUTES = 'attribute/fetchAttributes'
export const ADD_ATTRIBUTE = 'attribute/addAttribute'
export const UPDATE_ATTRIBUTE = 'attribute/updateAttribute'
export const FETCH_ATTRIBUTE_DETAILS = 'attribute/fetchAtrributeDetails'
export const DELETE_ATTRIBUTE = 'attribute/deleteAttribute'
export const ATTRIBUTE_GROUP_ADD = 'attribute/attributeGroupAdd'
export const ATTRIBUTE_GROUP_UPDATE = 'attribute/attributeGroupUpdate'
export const ATTRIBUTE_GROUP_LIST = 'attribute/attrbuteGroupList'
export const ATTRIBUTE_GROUP_DELETE = 'attribute/attributeGroupDelete'
export const ATTRIBUTE_IMAGE_UPLOAD = 'attribute/attributeImageUpload'
export const FETCH_ATTRIBUTES_FULFILLED = 'attribute/fetchAttributes/fulfilled'
export const ADD_ATTRIBUTE_FULFILLED = 'attribute/addAttribute/fulfilled'
export const UPDATE_ATTRIBUTE_FULFILLED = 'attribute/updateAttribute/fulfilled'
export const FETCH_ATTRIBUTE_DETAILS_FULFILLED = 'attribute/fetchAtrributeDetails/fulfilled'
export const DELETE_ATTRIBUTE_FULFILLED = 'attribute/deleteAttribute/fulfilled'
export const ATTRIBUTE_GROUP_ADD_FULFILLED = 'attribute/attributeGroupAdd/fulfilled'
export const ATTRIBUTE_GROUP_UPDATE_FULFILLED = 'attribute/attributeGroupUpdate/fulfilled'
export const ATTRIBUTE_GROUP_LIST_FULFILLED = 'attribute/attrbuteGroupList/fulfilled'
export const ATTRIBUTE_GROUP_DELETE_FULFILLED = 'attribute/attributeGroupDelete/fulfilled'

export const FETCH_ATTRIBUTES_PENDING = 'attribute/fetchAttributes/pending'
export const ADD_ATTRIBUTE_PENDING = 'attribute/addAttribute/pending'
export const UPDATE_ATTRIBUTE_PENDING = 'attribute/updateAttribute/pending'
export const FETCH_ATTRIBUTE_DETAILS_PENDING = 'attribute/fetchAtrributeDetails/pending'
export const DELETE_ATTRIBUTE_PENDING = 'attribute/deleteAttribute/pending'
export const ATTRIBUTE_GROUP_ADD_PENDING = 'attribute/attributeGroupAdd/pending'
export const ATTRIBUTE_GROUP_UPDATE_PENDING = 'attribute/attributeGroupUpdate/pending'
export const ATTRIBUTE_GROUP_LIST_PENDING = 'attribute/attrbuteGroupList/pending'
export const ATTRIBUTE_GROUP_DELETE_PENDING = 'attribute/attributeGroupDelete/pending'

export const FETCH_ATTRIBUTE_REJECTED = 'attribute/fetchAttributes/rejected'
export const ADD_ATTRIBUTE_REJECTED = 'attribute/addAttribute/rejected'
export const UPDATE_ATTRIBUTE_REJECTED = 'attribute/updateAttribute/rejected'
export const FETCH_ATTRIBUTE_DETAILS_REJECTED = 'attribute/fetchAtrributeDetails/rejected'
export const DELETE_ATTRIBUTE_REJECTED = 'attribute/deleteAttribute/rejected'
export const ATTRIBUTE_GROUP_ADD_REJECTED = 'attribute/attributeGroupAdd/rejected'
export const ATTRIBUTE_GROUP_UPDATE_REJECTED = 'attribute/attributeGroupUpdate/rejected'
export const ATTRIBUTE_GROUP_LIST_REJECTED = 'attribute/attrbuteGroupList/rejected'
export const ATTRIBUTE_GROUP_DELETE_REJECTED = 'attribute/attributeGroupDelete/rejected'


