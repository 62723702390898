import {Table, Button, Result, Image, Avatar} from "antd";
import React, { useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import ProdctThunk from "../../../../redux/thunk/ProductThunk";
import Toast from "../../../../components/Notification/Toast";
import dayjs from "dayjs";
import AddVariationForm from "../../../../components/Form/AddVariantForm";
import {VARIANT_UNLINK_FULFILLED} from "../../../../redux/types/ProductActionTypes";

export default function ProductVariant({present, variants, onFinish, getProductDetails}) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleOk = () => {
        // Perform additional actions if needed
        setModalVisible(false);
    };

    const dispatch = useDispatch();
    const [params] = useSearchParams();
    let id = params.get('id');
    if (id) {
        const inputString = id.split('/');
        id = inputString[0]
    }
    let sku = params.get('sku');
    if (sku) {
        const inputString = sku.split('/');
        sku = inputString[0]
    }
    const onSelectChange = (selectedKeys) => {
        setSelectedRowKeys(selectedKeys);
    };

    const clearVariantOf = async () => {
        const payload = await dispatch(ProdctThunk.variantUnlink(selectedRowKeys));
        if (payload.type === VARIANT_UNLINK_FULFILLED) {
            getProductDetails();
            Toast.successNotification("Variant Unlinked Successfully");
            setSelectedRowKeys([]);
        } else {
            Toast.errorNotification(payload.error.message)
        }


        // Dispatch API call with the extracted structure
        // onSubmit(apiBody);

        // Clear the selectedRowKeys

    };


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            key: 'sku',
            title: 'SKU',
            dataIndex: 'sku',
            fixed: true,
            width: 100,
            render: (text, record) => (
                <Link className="sku_text" to={`/product/details?id=${record.id || record._id}/attributes`}>
                    {text}
                </Link>
            ),
        },
        {
            key: 'separator',
            title: ' ',
            dataIndex: 'separator',
            className: 'separator',
            fixed: true,
            width: 10,
        },
        {
            key: 'Label',
            title: 'Label',
            dataIndex: 'label',
            className: 'gray_text text_ellipes',
            width: 200,
        },
        {
            key: 'Thumbnail',
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            className: 'gray_text',
            width: 200,
            render: (thumbnail, index) => (
                <Avatar
                    key={index}
                    shape="square"
                    size={40}
                    icon={
                        <Image
                            preview={false}
                            src={thumbnail ? thumbnail : "https://placehold.co/400"} alt={'img'}/>
                    }
                />
            ),
        },

        {
            key: 'Created At',
            title: 'Created At',
            dataIndex: 'created_at',
            className: 'gray_text',
            width: 200,
            render: (updated_at) => (
                <div className="last_modified">
                    <p className={"text_ellipes"}>{dayjs(updated_at).format('MMM D, YYYY')}</p>
                </div>
            ),
        },
    ];
    // const [fixedTop, setFixedTop] = useState(false);
    const fixedTop=false;
    let locale = {
        emptyText: (
            <Result
                className={"empty_message_wrapper"}
                icon={""}
                title="Variations"
                subTitle="This product doesn't have any variations"
                extra={[
                    <Button className={"primary_btn"} type="primary" onClick={showModal}> Add variation </Button>,
                ]}
            />
        )
    };
    return (
        <>
            <div className="custom_table_ui" style={{textAlign: "right"}}>
                <div className={"add_variation_header"}>
                    <div className={"top_sec"}>
                        <div className={"left_sec"}>
                            <button className={"primary_btn"} onClick={showModal}>Add Variation</button>
                            <AddVariationForm
                                onFinish={onFinish}
                                present={present}
                                getProductDetails={getProductDetails}
                                visible={modalVisible}
                                onCancel={handleCancel}
                                onOk={handleOk}
                            />

                        </div>
                        <div className={"center_sec"}>
                            <div className={"unlink_wrapper"}
                                 style={{
                                     display: selectedRowKeys.length > 0 ? "inline-flex" : "none",
                                 }}
                            >
                                <p><i className={"icon"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9"
                                         fill="none">
                                        <path
                                            d="M4.19632 8.85276L0 4.65644L1.04908 3.60736L4.19632 6.7546L10.9509 0L12 1.04908L4.19632 8.85276Z"
                                            fill="#423F3F"/>
                                    </svg>
                                </i>{selectedRowKeys.length} Selected
                                </p>
                                <Button
                                    onClick={clearVariantOf}
                                    className={"secondary_btn"}
                                    style={{
                                        marginRight: "2px"
                                    }}
                                >
                                    Unlink
                                </Button>
                            </div>
                        </div>
                        <div className={"right_sec"}>
                        </div>
                    </div>
                    <div className="total_result">
                        <strong className={"total_count"}>Total results:</strong> <span
                        className={"count_text"}>{variants?.length} {variants?.length > 1 ? "variations": "variation"} found</span>
                    </div>
                </div>
                {Array.isArray(variants) ? (
                    <Table
                        locale={locale}
                        dataSource={variants}
                        columns={columns}
                        rowKey="_id"
                        rowSelection={{
                            type: 'checkbox',
                            columnWidth: "40px",

                            ...rowSelection
                        }}
                        scroll={{
                            x: "1000",
                        }}
                        sticky={{
                            offsetHeader: 0,
                        }}
                        fixed={fixedTop ? 'top' : 'bottom'}
                    />
                ) : (
                    <p>No Variants Found of Present SKU</p>
                )}
            </div>
        </>
    );
}



