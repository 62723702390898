import {Button, Select} from "antd";
import FilterEditor from "../../../../components/Editors/FilterEditor";
import {DeleteOutlined, DownOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";
import Toast from "../../../../components/Notification/Toast";

const {Option} = Select;
const FilterWrapper = ({
                           handleOpenFilterModal,
                           handleApplyFilter,
                           onSearch,
                           handleSearchAttribute,
                           handleSetFilter,
                           clearFilterData,
                           attributes,
                           onSearchEdit,
                           deleteFilter,
                           filterViewName,
                           clearSelectedFilter,
                           states,
                           setStates

                       }) => {
    return <div className={"sidebar_filter_wrapper"}>
        <div className={"filter_header"}>
            <div className={"left_sec"}>
                Filters
            </div>
            <div className={"button_blks"}>
                <Button className={"icon_btn upload_btn"} onClick={() => {
                    setStates(prevState=> ({...prevState, filterModalType: "list"}));
                    handleOpenFilterModal();
                }}>
                    <svg width="15" height="22" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99984 0.333252H4.6665L0.666504 4.33325V12.3333C0.666504 13.0666 1.2665 13.6666 1.99984 13.6666H9.99984C10.7332 13.6666 11.3332 13.0666 11.3332 12.3333V1.66659C11.3332 0.933252 10.7332 0.333252 9.99984 0.333252ZM9.99984 1.66659V12.3333H1.99984V4.88658L5.21984 1.66659H9.99984Z" fill="#423F3F"/>
                        <path d="M8.66683 7.66667L6.00016 10.3333L3.3335 7.66667L4.2735 6.72667L5.3335 7.78V5.01333L6.66683 5V7.78L7.72683 6.72L8.66683 7.66667Z" fill="#423F3F"/>
                    </svg>
                </Button>
                <Button className={"icon_btn save_btn"} onClick={() => {
                    if (!states.selectedAttributeFilters || states.selectedAttributeFilters.length === 0) {
                        Toast.errorNotification("Apply Filters to save them!");
                    } else {
                        setStates(prevState=> ({...prevState, filterModalType: "save"}));
                        handleOpenFilterModal();
                    }
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                         fill="none">
                        <path
                            d="M17.5 5.83333V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H14.1667L17.5 5.83333ZM15.8333 6.54167L13.4583 4.16667H4.16667V15.8333H15.8333V6.54167ZM10 15C10.6944 15 11.2847 14.7569 11.7708 14.2708C12.2569 13.7847 12.5 13.1944 12.5 12.5C12.5 11.8056 12.2569 11.2153 11.7708 10.7292C11.2847 10.2431 10.6944 10 10 10C9.30556 10 8.71528 10.2431 8.22917 10.7292C7.74306 11.2153 7.5 11.8056 7.5 12.5C7.5 13.1944 7.74306 13.7847 8.22917 14.2708C8.71528 14.7569 9.30556 15 10 15ZM5 8.33333H12.5V5H5V8.33333ZM4.16667 6.54167V15.8333V4.16667V6.54167Z"
                            fill="#423F3F"/>
                    </svg>
                </Button>
            </div>
        </div>
        <div className={"filter_body_wrapper"}>
            {
                states.secondScreen ? <>

                        <div className={"select_attribute_wrapper"}>
                            <div className={"back_btn_wrapper"}>
                                            <span className={"back_icon"} onClick={() => setStates(prevState=> ({...prevState, secondScreen: false}))}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     viewBox="0 0 20 20" fill="none">
                                                    <path
                                                        d="M6.52051 10.834L11.1872 15.5007L9.99967 16.6673L3.33301 10.0007L9.99967 3.33398L11.1872 4.50065L6.52051 9.16732H16.6663V10.834H6.52051Z"
                                                        fill="#423F3F"/>
                                                </svg>filter
                                            </span>
                            </div>
                            <div className={"form_group"}>
                                <label> Select {states.identifier.toLowerCase()}</label>
                                <div className={"select_wrapper attribute_Select_wrapper"}>
                                    <Select
                                        showSearch
                                        style={{width: 200}}
                                        placeholder="All Attributes"
                                        defaultValue={states.editFilter ? states.editFilter.name : undefined}
                                        onSearch={handleSearchAttribute}
                                        onSelect={onSearch}
                                        className={"attribute_select"}
                                        popupClassName={"filter_dropdown"}
                                    >
                                        {!!states.searchedAttributes && states.searchedAttributes.map(val => <Option
                                            key={val.id} id={val.id} value={val.nameToShow}>
                                            <div className={"filter_dropdown_value"}>
                                                <div className={"name_sec"}>
                                                    {val.nameToShow}
                                                </div>
                                                <div className={"select_type"}>
                                                    {val.type.toLowerCase()}
                                                </div>
                                            </div>

                                        </Option>)}
                                    </Select>
                                </div>
                            </div>
                            <div className={"select_wrapper attribute_Select_wrapper"}>

                                {

                                    (states.filterVal || states.editFilter || states.isEditable) &&
                                    <FilterEditor handleSetFilter={handleSetFilter} states={states} setStates={setStates} />

                                }
                                {

                                }
                            </div>
                            <div className={"filter_button_wrapper"}>
                                <Button className={"primary_btn"}
                                        disabled={(states.val || (states.selectedOption && ((states.selectedOption.name ? states.selectedOption.name : states.selectedOption) === 'is defined' || (states.selectedOption.name ? states.selectedOption.name : states.selectedOption) === 'is not defined'))) ? false : !states.activeButton}
                                        onClick={handleApplyFilter}>Apply Filter</Button>
                            </div>
                        </div>


                    </> :
                    <div style={{minHeight: '50%'}}>
                        <div className={"accordian_wrapper"}>
                            {filterViewName && <div>
                                <div className={"filter-clauses"}>
                                    <div style={{width: "200px"}}>
                                        <label>
                                        <span style={{display: "flex", gap: "10px", color: "#0D894F", textDecoration: "underline", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100%", whiteSpace: "nowrap"}}>
                                            View : <p>{filterViewName}</p>
                                        </span>
                                        </label>
                                    </div>
                                    <Button danger={true} onClick={clearSelectedFilter}>Clear</Button>
                                </div>
                                {states.val && states.val?.length && states.index !== states.val.length - 1 &&
                                    <div className={"separator and_seprater"}>
                                        <span>AND</span>
                                    </div>
                                }
                            </div>}
                            <div className={"accordian_header"}>
                                <div>
                                    Attributes
                                </div>
                                <div>
                                    {states.selectedAttributeFilters && states.selectedAttributeFilters.length ? <>
                                            {states.isDisplay ? <DownOutlined onClick={() => {
                                                setStates(prevState=>({...prevState, isDisplay: !states.isDisplay}))
                                            }

                                            }/> : <PlusOutlined onClick={() => setStates(prevState=>({...prevState, isDisplay: !states.isDisplay}))}/>}
                                        </> :
                                        <PlusOutlined onClick={() => {
                                            clearFilterData();
                                            setStates(prevState=> ({...prevState, identifier: "attributes", secondScreen: true}));
                                        }}/>
                                    }
                                </div>
                            </div>
                            <div style={{display: states.isDisplay ? 'block' : 'none'}}>

                                {states.selectedAttributeFilters && states.selectedAttributeFilters.length > 0 && states.selectedAttributeFilters.map((val, ind) => {

                                    return <div className={"filter_clause_wrapper"} key={ind}>
                                        {
                                            val.map((v, index) => {
                                                    let value = attributes?.find(attr => attr.name === v.name);
                                                    return <div key={index}>
                                                        <div className={"filter-clauses"}
                                                           >
                                                            <div className={"left_sec"}
                                                                 onClick={() => {
                                                                     setStates(prevState => ({...prevState, editFilter: {...v,type:v.match}}));
                                                                     onSearchEdit(v);
                                                                     setStates(prevState => ({...prevState, index: {ind, index}, secondScreen: true, isEditable:true}));
                                                                 }}>

                                                                <label>
                                                                                <span
                                                                                    className={"type_text"}>{value ? value?.nameToShow : v.name} </span>
                                                                </label>
                                                                <p>
                                                                    {v.match}
                                                                    &nbsp;
                                                                    <span>{v.value === true ? "True" : v.value === false ? 'False' :Array.isArray( v.value)?v.value.map((val,index)=><span>{val} {index===v.value.length-1?"":","}  </span>) :v.value}</span>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {!states.isPaginationProductLoading &&
                                                                    <DeleteOutlined

                                                                        onClick={() => deleteFilter(v, ind)}/>}
                                                            </div>

                                                        </div>
                                                        {val && val?.length && index !== val.length - 1 &&
                                                            <div className={"separator and_seprater"}>
                                                                <span>AND</span>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            )
                                        }

                                        {states.selectedAttributeFilters && states.selectedAttributeFilters.length > 1 && ind !== states.selectedAttributeFilters.length - 1 &&

                                            <div>

                                                <div className={"operators_text"} onClick={() => {
                                                    clearFilterData();
                                                    setStates(prevVal=> ({...prevVal, updateIndex:ind, secondScreen: true}));
                                                }} style={{padding: '0'}}>
                                                    <Button className={"text_btn"}
                                                            icon={<PlusOutlined/>}
                                                    >AND</Button>

                                                </div>
                                                <div className={"separator"}>
                                                    <span>OR</span>
                                                </div>
                                            </div>}
                                    </div>
                                })}
                                {
                                    states.selectedAttributeFilters && states.selectedAttributeFilters.length > 0 &&
                                    <div className={"operators_text selected_operators_text"}>

                                        <Button
                                            icon={<PlusOutlined/>}
                                            className={"text_btn and_btn"} onClick={() => {
                                            clearFilterData();
                                            setStates(prevState=> ({...prevState, identifier: "attributes", isOrAnd: 'and', secondScreen: true}));

                                        }}> AND </Button>


                                        <Button
                                            icon={<PlusOutlined/>}
                                            className={"text_btn or_btn"} onClick={() => {
                                            clearFilterData();
                                            setStates(prevState=> ({...prevState, isOrAnd: 'or', secondScreen: true}));
                                        }}> OR</Button>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    </div>
}


export default FilterWrapper;