export const CATEGORY_ADD = 'category/categoryAdd'
export const CATEGORY_LIST = 'category/categoryList'
export const CATEGORY_DELETE = 'category/categoryDelete'
export const CATEGORY_DETAILS = 'category/categoryDetails'
export const SUB_CATEGORY_ADD = 'category/subCategoryAdd'
export const CATEGORY_WITH_SUB_CATEGORIES = 'category/categoryWithSubCategories'

export const CATEGORY_ADD_FULFILLED = 'category/categoryAdd/fulfilled'
export const CATEGORY_LIST_FULFILLED = 'category/categoryList/fulfilled'
export const CATEGORY_DELETE_FULFILLED = 'category/categoryDelete/fulfilled'
export const CATEGORY_DETAILS_FULFILLED = 'category/categoryDetails/fulfilled'
export const SUB_CATEGORY_ADD_FULFILLED = 'category/subCategoryAdd/fulfilled'

export const CATEGORY_ADD_PENDING = 'category/categoryAdd/pending'
export const CATEGORY_LIST_PENDING = 'category/categoryList/pending'
export const CATEGORY_DELETE_PENDING = 'category/categoryDelete/pending'
export const CATEGORY_DETAILS_PENDING = 'category/categoryDetails/pending'
export const SUB_CATEGORY_ADD_PENDING = 'category/subCategoryAdd/pending'

export const CATEGORY_ADD_REJECTED = 'category/categoryAdd/rejected'
export const CATEGORY_LIST_REJECTED = 'category/categoryList/rejected'
export const CATEGORY_DELETE_REJECTED = 'category/categoryDelete/rejected'
export const CATEGORY_DETAILS_REJECTED = 'category/categoryDetails/rejected'
export const SUB_CATEGORY_ADD_REJECTED = 'category/subCategoryAdd/rejected'