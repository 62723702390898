import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Input, Button, Select, Form} from "antd";
import Toast from "../../../components/Notification/Toast";
import "react-quill/dist/quill.snow.css";
import productThunk from "../../../redux/thunk/ProductThunk";
import AttributeThunk from "../../../redux/thunk/AttributeThunk";
import {useNavigate} from "react-router-dom";
import ProductThunk from "../../../redux/thunk/ProductThunk";
import {PRODUCT_SKU_VALIDATION_FULFILLED,ADD_PRODUCT_FULFILLED} from "../../../redux/types/ProductActionTypes"
const {Option}=Select;
const AddProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sku, setSku] = useState("");
    const [productName, setProductName] = useState("");
    const [taxonomy,setTaxonomy]=useState(null);
    const [price,setPrice]=useState(null);
    const [brandName,setBrandName]=useState(null);
    const [parentSku,setParentSku]=useState("");
    const [isErrorSku, setIsErrorSku] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [form]=Form.useForm();
    const [submit,setSubmit]=useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const selectRef = useRef(null);
    const {attributes}=useSelector(state=>state.attributes);
    useEffect(() => {
        dispatch(AttributeThunk.fetchAttributes());
    }, []);
    useEffect(()=>{
        if(attributes){
            let taxonomy=attributes?.find(val=>val.name==='taxonomy_categories');
            let brand=attributes?.find(val=>val.name==='brand_name');
            if(taxonomy){
                form.setFieldsValue({
                    taxonomyOptions:taxonomy?.selectOption,
                })
            }
            if(brand){
                form.setFieldsValue({
                    brandOptions:brand?.selectOption
                })
            }
        }
    },[attributes]);
    useEffect(()=>{
        if(submit){
            onFinish();
        }

    },[brandName,taxonomy,submit])

    const renderDynamicFields = (type,name) => {
        if (type === "select" || type === "dropdown") {
            return (
                <>
                    <Form.Item
                        rules={[
                            {type: "array",max:1, message: "Please select options!"},
                        ]}
                    >
                        <Select placeholder="Enter options"
                                ref={selectRef}
                                onChange={val=>{
                                    form.setFieldsValue({
                                        "brandSelectedOptions": val.slice(-1)
                                    });
                                    setBrandName(form.getFieldValue('brandSelectedOptions'));
                                    setTimeout(() => {
                                        selectRef.current.blur();
                                    }, 0);
                                }}
                                mode={type === "dropdown" || type === "select" ? "tags" : "default"}
                                value={brandName}
                                open={dropdownVisible}
                                onFocus={() => setDropdownVisible(true)}
                                onBlur={() => setDropdownVisible(false)}

                        >
                            {(form.getFieldValue(name) || []).map((opt, index) => (
                                <Option key={index} value={opt}>
                                    {opt}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                </>
            );
        } else if (type === "multiselect") {
            return (
                <Form.Item
                    name="taxonomySelectedOptions"

                    rules={[
                        {type: "array", message: "Please select options!"},
                    ]}
                >
                    <Select mode="tags"
                            onChange={val=>{
                                form.setFieldsValue({
                                    "taxonomySelectedOptions":val
                                });
                                setTaxonomy(form.getFieldValue('taxonomySelectedOptions'));
                            }}
                    >
                        {(form.getFieldValue(name) || []).map((opt, index) => (
                            <Option key={index} value={opt}>
                                {opt}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            );

        }
        return null;
    };

    const onFinish = async () => {
        setIsErrorSku(false);
        if (sku === "" || productName==='' || !taxonomy || (taxonomy && !taxonomy.length) || !price || !brandName || (brandName && brandName.length>1)|| parentSku==='') {
            setIsEmpty(true);
            setSubmit(false);
            return;
        }
        if(sku===parentSku){
            Toast.errorNotification("SKU can't be equal to Parent SKU");
            setSubmit(false);
            return;
        }
        const payloadSku = await dispatch(ProductThunk.productSkuValidation({sku:sku}));

        if(payloadSku.type === PRODUCT_SKU_VALIDATION_FULFILLED){
            if(!payloadSku.payload.data.isAvailable){
                setIsErrorSku(true);
                setSubmit(false);
                return;
            }
        }

        const payload = await dispatch(productThunk.addProduct({
            sku,
            attributes: [
                {
                    name:"product_name",
                    value:productName.toString(),
                    type:"text"
                },
                {
                    name:"taxonomy_categories",
                    value: taxonomy,
                    type:"multiselect"
                },
                {
                    name:"brand_name",
                    value: brandName.toString(),
                    type:'dropdown'
                },
                {
                    name:"price",
                    value: price,
                    type:'number'
                },
                {
                    name:"parent_sku",
                    value: parentSku,
                    type:'text'
                }
            ],
        }));
        if (payload.type === ADD_PRODUCT_FULFILLED) {
            Toast.successNotification("Product Added Successfully");
            setSku("");
            dispatch(AttributeThunk.fetchAttributes());
            dispatch(ProductThunk.productList({
                skip:0,
                limit:25
            }));
            navigate("/product/details?id=" + payload.payload.data.productId)
        } else {
            Toast.errorNotification(payload.error.message);
        }
    };

    const onCancel = () => {
        navigate("/product/list")
    }


    return (
        <div className="product_detail_wrapper">
            <div className="product_sidebar">
                <h4 className="sidebar_title">Product Details</h4>

                <div className="form_fields">
                    <div className="form_group">
                        <label className="field_label">SKU</label>
                        <Input value={sku} onChange={(e) => {
                            setIsErrorSku(false)
                            setSku(e.target.value)
                            setIsEmpty(false)
                        }
                        } placeholder=""/>
                        {isErrorSku && <p
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                marginBottom: "0px",
                            }}
                            className="error_message">SKU Already Exist</p>}
                        {
                            isEmpty && !sku.length && <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                }}
                                className="error_message">Please Enter SKU</p>
                        }
                    </div>

                    <div className="form_group">
                        <label

                            className="field_label">Product Name</label>
                        <Input value={productName}
                               onChange={(e) => setProductName(e.target.value)} placeholder=""/>
                        {
                            isEmpty && !productName.length && <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                }}
                                className="error_message">Please Enter Product Name</p>
                        }
                    </div>

                    <div className="form_group">
                        <label
                            className="field_label">Price</label>
                        <Input value={price}
                               type={"number"}
                               onChange={(e) => setPrice(parseFloat(e.target.value))} placeholder=""/>
                        {
                            isEmpty && !price && <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                }}
                                className="error_message">Please Enter Price</p>
                        }
                    </div>

                    <div className="form_group">
                        <label
                            className="field_label">Parent SKU</label>
                        <Input value={parentSku}
                               onChange={(e) => setParentSku(e.target.value)} placeholder=""/>

                        {
                            isEmpty && !parentSku.length && <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                }}
                                className="error_message">Please Enter Parent SKU</p>
                        }
                    </div>

                    <div className="form_group">
                        <label

                            className="field_label">Taxonomy/Categories</label>
                        {/*<Input value={taxonomy}*/}
                        {/*       onChange={(e) => setTaxonomy(e.target.value)} placeholder=""/>*/}
                        {/*<Select value={taxonomy} onChange={val=>setTaxonomy(val)}  placeholder={"Select an Option"} showSearch={true} >*/}
                        {/*    {taxonomyOptions.map((val,index)=><Select.Option key={index} value={val}>{val}</Select.Option>)}*/}
                        {renderDynamicFields("multiselect",'taxonomyOptions')}
                        {/*<Select.Option value={"Archived"} key={3}>Archived</Select.Option>*/}
                        {/*</Select>*/}
                        {
                            isEmpty && (!taxonomy || !taxonomy.length )&& <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                }}
                                className="error_message">Please Enter Taxonomy/Categories</p>
                        }
                    </div>

                    <div className="form_group">
                        <label
                            className="field_label">Brand Name</label>
                        {/*<Input value={brandName}*/}
                        {/*       onChange={(e) => setBrandName(e.target.value)} placeholder=""/>*/}
                        {/*<Select value={brandName} onChange={val=>setBrandName(val)} placeholder={"Select an Option"} showSearch={true} >*/}
                        {/*    {brand.map((val,index)=><Select.Option key={index} value={val}>{val}</Select.Option>)}*/}
                        {/*</Select>*/}
                        {renderDynamicFields("dropdown",'brandOptions')}

                        {
                            isEmpty && (!brandName || !brandName.length || brandName.length>1) && <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                }}
                                className="error_message">Please Enter Brand Name</p>
                        }
                    </div>

                    <div className="btn_wrapper">
                        <Button className="secondary_btn" type="primary" onClick={onCancel}>Cancel</Button>
                        <div className="seprater_28"></div>
                        <Button className="primary_btn" type="primary" onClick={()=>{
                            setTaxonomy(form.getFieldValue('taxonomySelectedOptions'));
                            if(form.getFieldValue('brandSelectedOptions') && form.getFieldValue('brandSelectedOptions').length ){
                                setBrandName(form.getFieldValue('brandSelectedOptions'));
                            }
                            setSubmit(true);

                        }}>Create Product</Button>
                    </div>
                </div>
            </div>
            <div className="detail_right_wrapper">
                <div className="empty_area">
                    <div className="placeholder_img">
                        <img src="/images/newproduct_placeholder.svg" alt=""/>
                    </div>
                    <h5 className="empty_title">Add Details</h5>
                    <p className="empty_desc">Add Product Details and Include the Entire Range of Attributes in One
                        Centralized Location</p>
                </div>
            </div>
        </div>
    );
};

export default AddProduct;