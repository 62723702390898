import React from "react";

import { Button, Dropdown, Input, Popconfirm, Skeleton, Spin, Tabs } from "antd";
import { Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from "lodash";
import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLoader";
import DiscardChangesDialog from "../../../../components/Modal/DiscardChangesDialog";

dayjs.extend(utc);


const ProductDetailWrapper = (props) => {
    return (
        <div className="product_detail_wrapper">
            <div className="product_sidebar detail_page_sidebar">
                <div className="review_changes_wrap" style={{ display: props.states.enableSubmit ? "flex" : 'none', }}>
                    {!!props.states.reviewChanges && <p>Review {props.states.reviewChanges} Changes</p>}

                    {props.states.enableSubmit && <>
                        <button className="primary_btn"
                            onClick={props.handleSubmit}
                        >Save Changes
                        </button>
                    </>}

                </div>
                
                <div className="sidebar_header_wrap">
                    <DiscardChangesDialog
                        visible={props.states.showDialog}
                        onConfirm={props.handleNavigateToAnotherPage}
                        onCancel={props.handleCancelDiscardChanges}
                    />
                    <div className="back_btn_wrap">
                        {props.productDetail?.variation_of ? (<>
                            <Button className="back_btn" type="button" onClick={props.handleNavigateToAnotherPage}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                    viewBox="0 0 20 20" fill="none">
                                    <path
                                        d="M6.52075 10.8333L11.1874 15.5L9.99992 16.6667L3.33325 10L9.99992 3.33334L11.1874 4.50001L6.52075 9.16668H16.6666V10.8333H6.52075Z"
                                        fill="#423F3F" />
                                </svg>
                            </Button>
                            Back
                        </>) : (<>
                            <Button className="back_btn" type="button" onClick={props.handleNavigateToAnotherPage}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                    viewBox="0 0 20 20" fill="none">
                                    <path
                                        d="M6.52075 10.8333L11.1874 15.5L9.99992 16.6667L3.33325 10L9.99992 3.33334L11.1874 4.50001L6.52075 9.16668H16.6666V10.8333H6.52075Z"
                                        fill="#423F3F" />
                                </svg>
                            </Button>
                            All Products
                        </>)}
                    </div>
                    <div>
                        <Popconfirm
                            title="Are you sure you want to delete this product?"
                            onConfirm={() => props.handleDeleteProduct()}

                            okText="Yes"
                            cancelText="No"
                        >  <Button danger>Delete Product</Button></Popconfirm>
                    </div>
                </div>

                <div className="draft_btn_wrap">
                    {
                        !props.states.isProductDetailsLoaded ? <Skeleton.Avatar size={"small"} active={true} /> :
                            (<Dropdown
                                menu={{
                                    items: props.statusItems,
                                }}
                                placement="bottomLeft"
                                trigger={['click']}
                                className={"draft_btn"}
                                overlayClassName="draft_dropdown"
                            >


                                <Button
                                    className={props.productDetail?.status ? props.productDetail?.status === 'Completed' ? 'complete_tag' : props.productDetail?.status === 'Archived' ? 'archived_tag' : 'draft_tag' : "draft_tag"}>
                                    {props.productDetail?.status ? props.productDetail?.status : "Draft"}</Button>

                            </Dropdown>)
                    }
                </div>

                <div className="sidebar_product_detail">
                    <Spin

                        spinning={props.states.isThumbnailUpdated}
                    >
                        <div className="product_image"
                        >
                            {!props.states.isProductDetailsLoaded ? <Skeleton.Image active={true} /> :

                                <img
                                    src={Array.isArray(props.productDetail?.thumbnail) ? props.productDetail?.thumbnail[0].link : props.productDetail?.thumbnail ? props.productDetail?.thumbnail : props.imageUrl}
                                    alt=""

                                />}

                            <div className={"image_upload_wrapper"}>

                                <Upload
                                    name="file"
                                    m accept="image/*"
                                    action=""
                                    beforeUpload={() => false}
                                    onChange={_.debounce(props.handleUpload, 100)}
                                    fileList={[]}
                                    showUploadList={false}
                                    className={"btn upload_btn"}
                                >
                                    <UploadOutlined />
                                    Upload
                                </Upload>
                                {props.productDetail?.thumbnail ?
                                    <Popconfirm overlayClassName={"custom_popover_wraper"}
                                        title={"Are you sure you want to delete?"}
                                        onConfirm={props.handleThumbnailDelete}
                                        okText="Yes"
                                        cancelText="No">
                                        <button
                                            className={"btn delete_btn"}>
                                            <DeleteOutlined />
                                            Delete
                                        </button>
                                    </Popconfirm>
                                    : <></>}
                            </div>

                        </div>
                    </Spin>

                    <div className={"label_text"}>
                        {!props.states.isProductDetailsLoaded ? <>
                            <Skeleton.Avatar size={"small"}

                                active={true} />
                        </> : <>
                            {props.states.isEditingLabel ? (<Input onKeyUp={(e) => {
                                if (e.key === 'Enter' || e.keyCode === 13) {
                                    props.handleLabelSubmit();
                                }
                            }} value={props.editedLABEL === '' ? undefined : props.editedLABEL} placeholder={"PRODUCT NAME"}
                                onChange={props.handleLabelChange} onBlur={props.handleLabelSubmit}
                                autoFocus
                                rootClassName={"custom_input_field"}
                            />) : (
                                <p onClick={props.handleLabelEdit}>{props.editedLABEL === '' ? "PRODUCT NAME" : props.editedLABEL}</p>)}
                        </>}

                    </div>

                    <div className="product_info">
                        <label>SKU</label>
                        {!props.states.isProductDetailsLoaded ? <Skeleton.Input size={"small"} active={true} /> : <>
                            {props.states.isEditing ? (<Input onKeyUp={(e) => {
                                if (e.key === 'Enter' || e.keyCode === 13) {
                                    props.handleSkuSubmit();
                                }
                            }} value={props.editedSKU} onChange={props.handleSkuChange} onBlur={props.handleSkuSubmit}
                                autoFocus
                            />) : (<p onClick={props.enableSkuEdit}>{props.editedSKU}</p>)}
                        </>}
                    </div>
                    {props.states.isProductDetailsLoaded && props.productDetail?.variation_of ? <div className="product_info">
                        <label
                            style={{
                                width: "auto"
                            }}
                        >Variation Of</label>
                        <p
                            className={"add_variation_tag"}
                            style={{
                                marginLeft: "4px"
                            }}
                            onClick={() => {
                                props.navigate(`/product/details?sku=${props.productDetail?.variation_of}/attributes`)
                            }}

                        > {props.productDetail?.variation_of} </p>
                    </div> : <></>

                    }

                    {props.states.isProductDetailsLoaded && props.productDetail?.parent_sku ? <div className="product_info">
                        <label
                            style={{}}
                        >Parent SKU</label>
                        <p
                            className={"add_variation_tag"}
                            style={{
                                marginLeft: "4px",
                                cursor: "default",
                                // remove underline
                                textDecoration: "none",
                            }}

                        > {props.productDetail?.parent_sku} </p>
                    </div> : <></>

                    }

                </div>
                {props.states.isProductDetailsLoaded && props.productDetail?.children && props.productDetail?.children.length > 0 &&

                    <div className={"variation_count"}>
                        <span className={"variation_icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M3.31242 16.4999L2.60409 16.2083C2.17353 16.0277 1.88534 15.7152 1.7395 15.2708C1.59367 14.8263 1.61797 14.3888 1.81242 13.9583L3.31242 10.7083V16.4999ZM6.64575 18.3333C6.18742 18.3333 5.79506 18.1701 5.46867 17.8437C5.14228 17.5173 4.97909 17.1249 4.97909 16.6666V11.6666L7.18742 17.7916C7.22909 17.8888 7.27075 17.9826 7.31242 18.0729C7.35409 18.1631 7.40964 18.2499 7.47909 18.3333H6.64575ZM10.9374 18.2499C10.493 18.4166 10.0624 18.3958 9.64575 18.1874C9.22909 17.9791 8.93742 17.6527 8.77075 17.2083L5.06242 7.04161C4.89575 6.59717 4.90964 6.16314 5.10409 5.73953C5.29853 5.31592 5.61797 5.02772 6.06242 4.87495L12.3541 2.58328C12.7985 2.41661 13.2291 2.43745 13.6458 2.64578C14.0624 2.85411 14.3541 3.1805 14.5208 3.62495L18.2291 13.7916C18.3958 14.2361 18.3819 14.6701 18.1874 15.0937C17.993 15.5173 17.6735 15.8055 17.2291 15.9583L10.9374 18.2499ZM9.14575 8.33328C9.38186 8.33328 9.57978 8.25342 9.7395 8.0937C9.89923 7.93397 9.97909 7.73606 9.97909 7.49995C9.97909 7.26383 9.89923 7.06592 9.7395 6.9062C9.57978 6.74647 9.38186 6.66661 9.14575 6.66661C8.90964 6.66661 8.71173 6.74647 8.552 6.9062C8.39228 7.06592 8.31242 7.26383 8.31242 7.49995C8.31242 7.73606 8.39228 7.93397 8.552 8.0937C8.71173 8.25342 8.90964 8.33328 9.14575 8.33328ZM10.3541 16.6666L16.6458 14.3749L12.9374 4.16661L6.64575 6.45828L10.3541 16.6666Z"
                                    fill="#423F3F" />
                            </svg>
                        </span>
                        <p onClick={() => {
                            if (props.sku) {
                                props.navigate('/product/details?sku=' + props.sku + '/variations')
                            } else {
                                props.navigate('/product/details?id=' + props.productDetail.id + '/variations')
                            }

                            props.setStates(prevState => ({ ...prevState, activeTab: 'variations' }));

                        }}>{props.productDetail.children.length} {props.productDetail.children && props.productDetail.children.length > 1 ? " Variations" : " Variation"}</p>
                    </div>}
                <div className="card_create_wrap">

                    <div className="card_created_ui">
                        <label>Created</label>
                        {!props.states.isProductDetailsLoaded ? <Skeleton.Input size={"small"} active={true} /> :
                            <p><span className="icon">
                            </span>

                                {dayjs(props.productDetail?.created_at).utc(true).local().format('YYYY-MM-DD HH:mm:ss')}</p>

                        }

                    </div>


                    <div className="card_created_ui">
                        <label>Modified</label>
                        {!props.states.isProductDetailsLoaded ? <Skeleton.Input size={"small"} active={true} /> :
                            <p><span className="icon">

                            </span>{dayjs(props.productDetail?.updated_at).utc(true).local().format('YYYY-MM-DD HH:mm:ss')}
                            </p>}
                    </div>

                </div>
            </div>
            <div className="detail_right_wrapper detail_custom_width">
                <div className="top_tabbar">
                    {!props.states.isProductDetailsLoaded ? <SkeletonLoader /> :

                        ((props.productDetail && props.productDetail.id) || (props.states.body && props.states.body.length)) && (
                            <Tabs rootClassName="custom_tabs" items={(props.productDetail?.variation_of) ? props.columns : props.tabs}
                                activeKey={props.states.activeTab}
                                onChange={(key) => {
                                    props.setStates(prevState => ({ ...prevState, activeTab: key }));                                    
                                    if (props.sku) {
                                        props.navigate('/product/details?sku=' + props.sku + '/' + key)
                                    } else {
                                        props.navigate('/product/details?id=' + props.productDetail.id + '/' + key)
                                    }
                                }} />

                        )}

                </div>
            </div>
        </div>
    )

};

export default ProductDetailWrapper;