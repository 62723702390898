import api from "../../config/api";

const CategoryService ={

    addCategory: async (newCategory) => {
        return await api.post('/category/add', {...newCategory});
    },
    subCategoryAdd: async (newSubCategory) => {
        return await api.post('/sub/category/add', {...newSubCategory});
    },
    fetchCategoryDetails: async (categoryId) => {
        return await api.get(`/category/?categoryId=${categoryId}`);
    },

    getCategoriesList: async () => {
        return await api.post('/category/list');
    },
    deleteCategory: async (categoryId) => {
        return await api.delete(`/category/delete?categoryId=${categoryId}`);
    },
    getCategoryWithSubCategories: async () => {
        return await api.get('/category/sub/list');
    },
};

export default CategoryService;
