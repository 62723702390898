import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Checkbox, Form, Input, Modal, Popconfirm, Tag, Tooltip} from 'antd';
import AttributeThunk from '../../../redux/thunk/AttributeThunk';
import Toast from '../../../components/Notification/Toast';
import { Table } from 'antd';
import EditAttributeGroupModal from '../../../components/Modal/EditAttributeGroupModal';
import {
    CheckOutlined, CloseCircleOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import {FETCH_ATTRIBUTE_REJECTED,ATTRIBUTE_GROUP_ADD_FULFILLED,ATTRIBUTE_GROUP_DELETE_FULFILLED,ATTRIBUTE_GROUP_UPDATE_FULFILLED} from "../../../redux/types/AttributeActionTypes";    

const AttributeGroup = () => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [label, setLabel] = useState('');
    const { attributes , attributeGroupList} = useSelector((state) => state.attributes);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchedAttributeList,setSearchAttributeList]=useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalAttributes, setModalAttributes] = useState([]);
    const [searchedAttributes,setSearchAttributes]=useState([]);

    const showModalForExtraAttributes = (attributes) => {
        setModalAttributes(attributes);
        setModalVisible(true);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    useEffect(()=>{
        setSearchAttributes(attributes);
    },[attributes])
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            try {
                const payload = await dispatch(AttributeThunk.fetchAttributes(signal));
                if (payload.type === FETCH_ATTRIBUTE_REJECTED) {
                    Toast.errorNotification(payload.error.message);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            abortController.abort();
        };
    }, [dispatch]);
    useEffect(()=>{
        setSearchAttributeList([...attributeGroupList]);
    },[attributeGroupList])

    const handleNext = () => {
        if(!label){
            Toast.errorNotification("Please Enter Label");
            return;
        }
        if (currentStep === 0) {
            setCurrentStep(1);
        }
    };

    const handlePrevious = () => {
        if (currentStep === 1) {
            setCurrentStep(0);
        }
    };

    const handleAttributeSelect = (attributeId) => {
        setSelectedAttributes((prevSelected) => [...prevSelected, attributeId]);
    };

    const handleAttributeRemove = (attributeId) => {
        setSelectedAttributes((prevSelected) => prevSelected.filter((id) => id !== attributeId));
    };

    const handleLabelChange = (value) => {
        setLabel(value);
    };

    const handleGroupSave = async () => {
        if(!label){
            Toast.errorNotification("Please Enter Label");
            return;
        }
        // Create the request body based on your structure
        const requestBody = {
            label,
            attributes: selectedAttributes.map((id) => {
                // Get the attribute details from the attributes array
                const attribute = attributes.find((attr) => attr.id === id);
                return {
                    id: attribute.id,
                    name: attribute.name,
                    type: attribute.type,
                    description: attribute.description,
                    nameToShow: attribute.nameToShow,
                };
            }),
        };
       const payload = await dispatch(AttributeThunk.attributeGroupAdd(requestBody));
       if(payload.type === ATTRIBUTE_GROUP_ADD_FULFILLED){
           Toast.successNotification("Attribute Group Added Successfully");
           setSelectedAttributes([]);
           setLabel('');
           setCurrentStep(0);
           setIsModalVisible(false);
       }else{
              Toast.errorNotification(payload.error.message);
       }
       setSearchAttributes(attributes)


    };

    const handleDelete = async (attributeGroupId) => {
       const payload =  await dispatch(AttributeThunk.attributeGroupDelete(attributeGroupId));
        if(payload.type === ATTRIBUTE_GROUP_DELETE_FULFILLED) {
            Toast.successNotification("Attribute Group Deleted Successfully");
            setSelectedAttributes([]);
            setLabel('');
            setCurrentStep(0);
            setIsModalVisible(false);
        }else{
            Toast.errorNotification(payload.error.message);
        }
    }


    useEffect(() => {
        const fetchAttributeGroupList = async () => {

           await dispatch(AttributeThunk.attrbuteGroupList());
        }

       fetchAttributeGroupList();

    } ,[dispatch])
    const columns = [
        {
            title: 'Group Name',
            dataIndex: 'labelToShow',
            key: 'name',
            width:'300px',
        },
        {
            title: 'Attributes',
            dataIndex: 'attributes',
            key: 'attributes',
            width:'250px',
            render: (attributes) => (
                <div>
                    {attributes.slice(0, 2).map((attribute, index) => (
                        <div className={"attribute_badge_wrap"} key={attribute.id}>
                            <Tooltip title={`Type: ${attribute.type}`}>
                                <Tag className={"badge_icon"} color={index % 2 === 0 ? '' : ''}>
                                    {attribute.nameToShow}
                                </Tag>
                            </Tooltip>
                            {/* Add more details or buttons if needed */}
                        </div>
                    ))}
                    {attributes.length > 2 && (
                        <div  className="more_items">
                            <Tooltip title={`Show more attributes (${attributes.length} total)`}>
                                <span onClick={() => showModalForExtraAttributes(attributes)}>{`Show ${attributes.length-2} more...`}</span>
                            </Tooltip>
                        </div>
                    )}
                </div>
            ),

        },

        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'250px',
            render: (createdAt) => (
                <span>{dayjs(createdAt).format('MMM D, YYYY')}</span>
            ),
        },

        {
            title:'updated At',
            dataIndex:'updatedAt',
            key:'updatedAt',
            width:'250px',
            render: (updatedAt) => (
                <span>{dayjs(updatedAt).format('MMM D, YYYY')}</span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            fixed: 'right',
            render: (groupId) => (
                <div className={"btn_wrapper"}>
                    <Button  className={"icon_text_btn"} onClick={() => handleEdit(groupId)}>
                        <span className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M17.3047 6.81991C18.281 5.8436 18.281 4.26069 17.3047 3.28438L16.7155 2.69512C15.7391 1.71881 14.1562 1.71881 13.1799 2.69512L3.69097 12.1841C3.34624 12.5288 3.10982 12.9668 3.01082 13.4442L2.34111 16.6735C2.21932 17.2607 2.73906 17.7805 3.32629 17.6587L6.55565 16.989C7.03302 16.89 7.47103 16.6536 7.81577 16.3089L17.3047 6.81991ZM16.1262 4.46289L15.5369 3.87363C15.2115 3.5482 14.6839 3.5482 14.3584 3.87363L13.4745 4.75755L15.2423 6.52531L16.1262 5.6414C16.4516 5.31596 16.4516 4.78833 16.1262 4.46289ZM14.0638 7.70382L12.296 5.93606L4.86948 13.3626C4.75457 13.4775 4.67577 13.6235 4.64277 13.7826L4.23082 15.769L6.21721 15.3571C6.37634 15.3241 6.52234 15.2453 6.63726 15.1303L14.0638 7.70382Z" fill="#667085"/>
                            </svg>
                        </span>Edit</Button>
                    <Popconfirm
                        title="Are you sure you want to delete this attribute?"
                        onConfirm={() => handleDelete(groupId)}
                        okText="Yes"
                        cancelText="No"
                    > <Button className={"icon_text_btn ant-btn-danger"}>
                            <span className={"icon"}>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M8.33317 8.12484C8.79341 8.12484 9.1665 8.49793 9.1665 8.95817V13.9582C9.1665 14.4184 8.79341 14.7915 8.33317 14.7915C7.87293 14.7915 7.49984 14.4184 7.49984 13.9582V8.95817C7.49984 8.49793 7.87293 8.12484 8.33317 8.12484Z" fill="#667085"/>
                                        <path d="M12.4998 8.95817C12.4998 8.49793 12.1267 8.12484 11.6665 8.12484C11.2063 8.12484 10.8332 8.49793 10.8332 8.95817V13.9582C10.8332 14.4184 11.2063 14.7915 11.6665 14.7915C12.1267 14.7915 12.4998 14.4184 12.4998 13.9582V8.95817Z" fill="#667085"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.9998 4.99984V4.1665C14.9998 2.78579 13.8806 1.6665 12.4998 1.6665H7.49984C6.11913 1.6665 4.99984 2.78579 4.99984 4.1665V4.99984H3.74984C3.2896 4.99984 2.9165 5.37293 2.9165 5.83317C2.9165 6.29341 3.2896 6.6665 3.74984 6.6665H4.1665V15.8332C4.1665 17.2139 5.28579 18.3332 6.6665 18.3332H13.3332C14.7139 18.3332 15.8332 17.2139 15.8332 15.8332V6.6665H16.2498C16.7101 6.6665 17.0832 6.29341 17.0832 5.83317C17.0832 5.37293 16.7101 4.99984 16.2498 4.99984H14.9998ZM12.4998 3.33317H7.49984C7.0396 3.33317 6.6665 3.70627 6.6665 4.1665V4.99984H13.3332V4.1665C13.3332 3.70627 12.9601 3.33317 12.4998 3.33317ZM14.1665 6.6665H5.83317V15.8332C5.83317 16.2934 6.20627 16.6665 6.6665 16.6665H13.3332C13.7934 16.6665 14.1665 16.2934 14.1665 15.8332V6.6665Z" fill="#667085"/>
                                        </svg>
                                   </span>
                            Delete</Button>

                    </Popconfirm>
                </div>
            ),
        },
    ];

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const handleEdit = async (groupId) => {
        const groupToEdit = attributeGroupList.find((group) => group.id === groupId);
        setSelectedGroup(groupToEdit);
        setEditModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setSearchAttributes(attributes);
        setEditModalVisible(false);
        setSelectedGroup(null);
    };

    const handleEditModalOk = async (editedLabel, editedAttributes) => {
        // Handle saving edited group details
        // Use dispatch and thunk to update the attribute group
        if(!editedLabel){
            Toast.errorNotification("Please Enter Label");
            return;
        }
        setSearchAttributes(attributes);

        const requestBody = {
            attributeGroupId: selectedGroup.id,
            label: editedLabel,
            attributes: editedAttributes.map((id) => {
                const attribute = attributes.find((attr) => attr.id === id);
                return {
                    id: attribute.id,
                    name: attribute.name,
                    type: attribute.type,
                    description: attribute.description,
                    nameToShow: attribute.nameToShow,
                };
            }),

        };

        const payload = await dispatch(AttributeThunk.attributeGroupUpdate(requestBody));
        // Handle success or error
        if (payload.type === ATTRIBUTE_GROUP_UPDATE_FULFILLED) {
            Toast.successNotification("Attribute Group Edited Successfully");
            setEditModalVisible(false);
            setSelectedGroup(null);
        } else {
            Toast.errorNotification(payload.error.message);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedGroup(null);
        setLabel('');
        setSelectedAttributes([]);
        setCurrentStep(0);
    };
    const handleSearch=(val)=>{
        let value=val.target.value;
        let temp=attributeGroupList.filter(v=>v.labelToShow.toLowerCase().includes(value.toLowerCase()));
        setSearchAttributeList([...temp]);
    }
    const handleAttributeSearch=(e)=>{
        let value=e.target.value;
        let temp=attributes.filter(val=>val.nameToShow.toLowerCase().includes(value.toLowerCase()));
        setSearchAttributes([...temp]);
    }
    return (
<div className={"attribute_list_wrapper"}>
    <div className={"attribute_list_header column_view"}>
        <div className={"top_sec"}>
            <h2>Attributes Group</h2>
            <div className="right_btn_wrap" style={{marginRight:'0px'}}>
            <div className="search_box border_ui" style={{marginRight:'16px'}}>
                <input type="text"
                       placeholder="Search"
                       onChange={handleSearch}
                />
                <span className="search_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z" stroke="#969696" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
            </div>

            <Button  type="primary" className="primary_btn" onClick={showModal}>
                Add Attribute Group
            </Button>
        </div>
        </div>

        <div className="total_result">
            <strong className={"total_count"}>Total results:</strong> <span className={"count_text"}>{attributeGroupList?.length} {attributeGroupList?.length > 1 ? "Attributes": "Attribute"} found</span>
        </div>
    </div>
    <Modal
        key="1"
        title="Add Attribute Group"
        open={isModalVisible}
        className={currentStep===0?'custom_modal custom_height add_group_modal edit_columns_modal':"custom_modal add_group_modal edit_columns_modal"}
        footer={null}
        onCancel={handleCancel}
    >
     <>
         <div className={"create_attribute"}>
             <div >
                 {currentStep === 0 && (
                     <Form layout={"vertical"} className={"custom_form_area"}>
                         <Form.Item label="Enter Label">
                             <Input value={label} onChange={(e) => handleLabelChange(e.target.value)} />
                         </Form.Item>
                         <Form.Item>
                             <Button type="primary" className="primary_btn" onClick={handleNext}>
                                 Next
                             </Button>
                         </Form.Item>
                     </Form>
                 )}

             </div>
         </div>
                 {currentStep === 1 && (
                     <div className={"column_filter_wrapper"}>
                         <div className={"body_content"}>
                             <div className={"left_col"}>
                                 <h3>Available {attributes?.length > 1 ? "Attributes" : "Attribute" }</h3>
                                 <div className={"inner_listing edit_column_list"}>
                                     {attributes.map((attribute) => (
                                         <div className={"checkbox_card"} key={attribute.id}>
                                             <Checkbox
                                                    checked={selectedAttributes.includes(attribute.id)}
                                                    onChange={() => {
                                                        // if selected attribute is already in the selectedAttributes array, remove it
                                                        if (selectedAttributes.includes(attribute.id)) {
                                                            handleAttributeRemove(attribute.id);
                                                        } else {
                                                            handleAttributeSelect(attribute.id);
                                                        }
                                                    }   }
                                                    //show the check icon if the attribute is already selected
                                                    icon={selectedAttributes.includes(attribute.id) ? <CheckOutlined /> : undefined}
                                                    ></Checkbox>
                                             {attribute.nameToShow} - {attribute.type}
                                         </div>
                                     ))}
                                 </div>
                             </div>
                             <div className={"divider"}></div>
                             <div className={"right_col"}>
                                 <h3>Selected  {selectedAttributes?.length > 1 ? "Attributes" : "Attribute"} {selectedAttributes?.length}</h3>
                                 <div className={"inner_listing"}>
                                     {selectedAttributes.map((selectedId) => {
                                         const selectedAttribute = attributes.find((attr) => attr.id === selectedId);
                                         return (
                                             <div className={"delete_card"} key={selectedAttribute.id} >
                                                 {selectedAttribute.nameToShow} - {selectedAttribute.type}
                                                 <Button
                                                     className={"delete_btn"}
                                                     icon={<CloseCircleOutlined />}
                                                     onClick={() => handleAttributeRemove(selectedAttribute.id)}>

                                                 </Button>
                                             </div>
                                         );
                                     })}
                                 </div>
                             </div>
                         </div>
                     </div>
                 )}
             <div className={"modal_footer"}>
                 {currentStep === 1 && (
                     <>
                         <Button className={"secondary_btn"} onClick={handlePrevious} style={{ marginRight: '8px' }}>
                             Previous
                         </Button>
                         <Button type="primary" className="primary_btn" onClick={handleGroupSave}>
                             Save Group
                         </Button>
                     </>

                 )}
             </div>
     </>
    </Modal>
    <Modal
        title="All Attributes"
        open={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        className={"custom_modal more_attribute_listing"}
    >
        {modalAttributes.map((attribute, index) => (
            <div className={"attribute_badge_wrap"} key={attribute.id}>
                <Tag className={"badge_icon"} color={index % 2 === 0 ? '' : ''}>
                    {attribute.nameToShow} - {attribute.type}
                </Tag>
                {/* Add more details or buttons if needed */}
            </div>
        ))}
    </Modal>

        
        <div className={"custom_table_ui"}>
    <Table  className={"attribute_table_list"}
            dataSource={searchedAttributeList.map(item=> ({...item, key: item.id}))} 
            columns={columns}
            pagination={{
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "75","100"],
            }}
    />
    {editModalVisible && (
        <EditAttributeGroupModal
            key={selectedGroup?.id} // Add a unique key for each modal
            visible={editModalVisible}
            onCancel={handleEditModalCancel}
            onOk={handleEditModalOk}
            selectedGroup={selectedGroup}
            attributes={attributes}
            handleAttributeSearch={handleAttributeSearch}
            searchedAttributes={searchedAttributes}
        />
    )}
        </div>

</div>

    );

};

export default AttributeGroup;
