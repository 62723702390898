// @TODO: DONT REMOVE COMMENTS
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Tree, Card, Typography, Space, Spin, Button, Input } from 'antd';
// import { CloseCircleOutlined } from "@ant-design/icons";
// import CategoryThunk from "../../../../redux/thunk/CategoryThunk";
//
// const { Text } = Typography;
//
// const defaultKeys = [];
//
// const ProductCategories = ({setStates , getData}) => {
//     const dispatch = useDispatch();
//     const { productCategories, isLoading } = useSelector((state) => state.categories);
//     const { productDetail } = useSelector((state) => state.products);
//     const [treeData, setTreeData] = useState([]);
//     const [checkedKeys, setCheckedKeys] = useState(defaultKeys);
//     const [expandedKeys, setExpandedKeys] = useState([]);
//     const [searchValue, setSearchValue] = useState("");
//     const [filteredTreeData, setFilteredTreeData] = useState([]);
//     // console.log('productCategories', productDetail);
//     // console.log('details', details);
//
//
//     const getKeysFromNames = (categories, paths) => {
//         const keys = [];
//         categories.forEach(category => {
//             // if path doest not starts with / then add / to the path
//             const path = category.path.startsWith('/') ? category.path : `/${category.path}`;
//             if (paths.includes(path)) {
//                 keys.push(category._id);
//             }
//         });
//         return keys;
//     };
//
//
//     useEffect(() => {
//         dispatch(CategoryThunk.getCategoryWithSubCategories());
//         getData();
//     }, [dispatch]);
//
//     useEffect(() => {
//         if (productCategories) {
//             const builtTree = buildTree(productCategories);
//             setTreeData(builtTree);
//             setFilteredTreeData(builtTree); // Initialize filtered tree data with the full tree
//
//             const categoriesAttachedToProduct = productDetail?.default_categories || [];
//             const defaultCategoryKeys = getKeysFromNames(productCategories, categoriesAttachedToProduct);
//             setCheckedKeys(defaultCategoryKeys || []);
//         }
//     }, [productCategories]);
//
//
//
//     const updateSelectedCategories = (checkedKeysValue) => {
//         const selectedCategories = checkedKeysValue.map(key => {
//             const node = findNodeByKey(treeData, key);
//             return { path: node?.data?.path, key: node?.data?._id };
//         });
//
//         // Update state with selected categories
//         setStates(prev => ({ ...prev, categories: selectedCategories }));
//     };
//     useEffect(() => {
//         if(checkedKeys.length){
//             setStates(prev => ({...prev, enableSubmit: true , reviewChanges:checkedKeys.length}));
//         }else{
//             setStates(prev => ({...prev, enableSubmit: false , reviewChanges:0}));
//         }
//
//         updateSelectedCategories(checkedKeys);
//
//     }, [checkedKeys.length ]);
//
//     const buildTree = (categories) => {
//         const idToNodeMap = {};
//         const rootNodes = [];
//
//         // Build a map of nodes
//         categories.forEach((category) => {
//             idToNodeMap[category._id] = {
//                 key: category._id,
//                 title: (
//                     <Space>
//                         <Text>{category.name_to_show}</Text>
//                         {!!category.n_children && (
//                             <Text type="secondary">({category.n_children} {category.n_children > 1 ? "subcategories" : "subcategory"})</Text>
//                         )}
//                     </Space>
//                 ),
//                 data: category,  // Store the entire category data for easy access later
//                 children: [],
//             };
//         });
//
//         // Populate the children nodes
//         categories.forEach((category) => {
//             if (category.parents_ids.length > 0) {
//                 const parentNode = idToNodeMap[category.parents_ids[category.parents_ids.length - 1]];
//                 if (parentNode) {
//                     parentNode.children.push(idToNodeMap[category._id]);
//                 }
//             } else {
//                 rootNodes.push(idToNodeMap[category._id]);
//             }
//         });
//
//         return rootNodes;
//     };
//
//     const onCheck = (checkedKeysValue) => {
//         setCheckedKeys(checkedKeysValue);
//         updateSelectedCategories(checkedKeysValue);
//     };
//
//     const handleRemoveCategory = (keyToRemove) => {
//         // Update checkedKeys by removing the selected key
//         const newCheckedKeys = checkedKeys.filter((key) => key !== keyToRemove);
//         setCheckedKeys(newCheckedKeys);
//         setStates(prev => ({...prev, categories: newCheckedKeys}));
//
//         // Manually uncheck the node in the tree by calling onCheck
//         onCheck(newCheckedKeys);
//         setStates(prev => ({...prev, enableSubmit: false , categories:[], reviewChanges:0}));
//     };
//
//     const collapseAllGroups = () => {
//         setExpandedKeys([]);
//     };
//
//     const clearAllChecked = () => {
//         setCheckedKeys([]);
//         // setStates(prev => ({...prev, categories: []}));
//         setStates(prev => ({...prev, enableSubmit: false ,categories:[], reviewChanges:0}));
//     };
//
//     const findNodeByKey = (nodes, key) => {
//         for (const node of nodes) {
//             if (node.key === key) {
//                 return node;
//             }
//             if (node.children) {
//                 const foundNode = findNodeByKey(node.children, key);
//                 if (foundNode) {
//                     return foundNode;
//                 }
//             }
//         }
//         return null;
//     };
//
//     const onSearch = (e) => {
//         const { value } = e.target;
//         setSearchValue(value);
//
//         if (!value) {
//             setFilteredTreeData(treeData);
//             setExpandedKeys([]);
//             return;
//         }
//
//         const filteredKeys = [];
//         const filterTree = (nodes) => {
//             return nodes
//                 .map((node) => {
//                     if (node.title.props.children[0].props.children.toLowerCase().includes(value.toLowerCase())) {
//                         filteredKeys.push(node.key);
//                         return { ...node };
//                     }
//                     if (node.children) {
//                         const filteredChildren = filterTree(node.children);
//                         if (filteredChildren.length > 0) {
//                             filteredKeys.push(node.key);
//                             return { ...node, children: filteredChildren };
//                         }
//                     }
//                     return null;
//                 })
//                 .filter((node) => node !== null);
//         };
//
//         const filteredData = filterTree(treeData);
//         setFilteredTreeData(filteredData);
//         setExpandedKeys(filteredKeys);
//         setStates(prev => ({...prev, reviewChanges:filteredKeys.length}));
//     };
//
//     return (
//         <Spin spinning={isLoading}>
//             <div className="category_wrapper product_category_page">
//                 <div className="categories_list_wrapper">
//                     {/* Left Column */}
//                     <div className="left_categories_wrapper">
//                         <div className="category_header_sec">
//                             <div className="top_sec">
//                                 <h3>Product Categories</h3>
//                                 <Button className="text_btn" onClick={collapseAllGroups}>
//                                     Collapse All Groups
//                                 </Button>
//                             </div>
//                             <div className="search_box border_ui">
//                                 <Input
//                                     placeholder="Search"
//                                     value={searchValue}
//                                     onChange={onSearch}
//                                 />
//                             </div>
//                         </div>
//                         <Card className="category_card_wrap" bordered={false} bodyStyle={{ backgroundColor: '#f0f5ff' }}>
//                             {/* Display Categories */}
//                             <Tree
//                                 checkable
//                                 onCheck={onCheck}
//                                 // on remove category
//                                 checkedKeys={checkedKeys}
//                                 treeData={filteredTreeData}
//                                 expandedKeys={expandedKeys}
//                                 onExpand={setExpandedKeys}
//                             />
//                         </Card>
//                     </div>
//                     <div className="divider"></div>
//
//                     {/* Right Column */}
//                     <div className="right_categories_wrapper">
//                         <div className="category_header_sec">
//                             <div className="top_sec">
//                                 <h3>Checked {checkedKeys?.length > 1 ? "Categories" : "Category"}</h3>
//                                 <Button className="text_btn" onClick={clearAllChecked}>
//                                     Clear All Checked
//                                 </Button>
//                             </div>
//                         </div>
//                         <Card className="category_card_wrap" bordered={false}>
//                             {/* Display Checked Categories */}
//                             <div>
//                                 {checkedKeys.map((key) => {
//                                     const node = findNodeByKey(treeData, key);
//                                     return (
//                                         <div className="subcategory_card" key={key}>
//                                             <div className="left_sec">
//                                                 <span className="category-name">{node?.data?.name_to_show}</span>
//                                                 <span className="category-path">{node?.data?.path}</span>
//                                             </div>
//                                             <div className="right_sec">
//                                                 <button
//                                                     onClick={() => handleRemoveCategory(key)}
//                                                     className="close_btn"
//                                                 >
//                                                     <CloseCircleOutlined />
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </Card>
//                     </div>
//                 </div>
//             </div>
//         </Spin>
//     );
// };
//
// export default ProductCategories;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, Card, Typography, Space, Spin, Button, Input } from 'antd';
import { CloseCircleOutlined } from "@ant-design/icons";
import CategoryThunk from "../../../../redux/thunk/CategoryThunk";

const { Text } = Typography;

const defaultKeys = [];

const ProductCategories = ({ setStates, getData }) => {
    const dispatch = useDispatch();
    const { productCategories, isLoading } = useSelector((state) => state.categories);
    const { productDetail } = useSelector((state) => state.products);
    const [treeData, setTreeData] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState(defaultKeys);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filteredTreeData, setFilteredTreeData] = useState([]);

    const getKeysFromNames = (categories, paths) => {
        const keys = [];
        categories.forEach(category => {
            const path = category.path.startsWith('/') ? category.path : `/${category.path}`;
            if (paths.includes(path)) {
                keys.push(category._id);
            }
        });
        return keys;
    };

    useEffect(() => {
        dispatch(CategoryThunk.getCategoryWithSubCategories());
        getData();
    }, [dispatch]);

    useEffect(() => {
        if (productCategories) {
            const builtTree = buildTree(productCategories);
            setTreeData(builtTree);
            setFilteredTreeData(builtTree);

            const categoriesAttachedToProduct = productDetail?.default_categories || [];
            const defaultCategoryKeys = getKeysFromNames(productCategories, categoriesAttachedToProduct);
            setCheckedKeys(defaultCategoryKeys || []);
        }
    }, [productCategories]);

    const updateSelectedCategories = (checkedKeysValue) => {
        const selectedCategories = checkedKeysValue.map(key => {
            const node = findNodeByKey(treeData, key);
            return { path: node?.data?.path, key: node?.data?._id };
        });

        setStates(prev => ({ ...prev, categories: selectedCategories }));
    };

    useEffect(() => {
        if (checkedKeys.length) {
            setStates(prev => ({ ...prev, enableSubmit: true, reviewChanges: checkedKeys.length }));
        } else {
            setStates(prev => ({ ...prev, enableSubmit: false, reviewChanges: 0 }));
        }

        updateSelectedCategories(checkedKeys);

    }, [checkedKeys.length]);

    const buildTree = (categories) => {
        const idToNodeMap = {};
        const rootNodes = [];

        categories.forEach((category) => {
            idToNodeMap[category._id] = {
                key: category._id,
                title: (
                    <Space>
                        <Text>{category.name_to_show}</Text>
                        {!!category.n_children && (
                            <Text type="secondary">({category.n_children} {category.n_children > 1 ? "subcategories" : "subcategory"})</Text>
                        )}
                    </Space>
                ),
                data: category,
                children: [],
            };
        });

        categories.forEach((category) => {
            if (category.parents_ids.length > 0) {
                const parentNode = idToNodeMap[category.parents_ids[category.parents_ids.length - 1]];
                if (parentNode) {
                    parentNode.children.push(idToNodeMap[category._id]);
                }
            } else {
                rootNodes.push(idToNodeMap[category._id]);
            }
        });

        return rootNodes;
    };

    const onCheck = (checkedKeysValue, info) => {
        const clickedKey = info.node.key;

        // Toggle the clicked key in the checkedKeys state
        setCheckedKeys(prev => {
            if (prev.includes(clickedKey)) {
                return prev.filter(key => key !== clickedKey);
            } else {
                return [...prev, clickedKey];
            }
        });

        // Update the selected categories
        updateSelectedCategories([clickedKey]);
    };

    const handleRemoveCategory = (keyToRemove) => {
        const newCheckedKeys = checkedKeys.filter((key) => key !== keyToRemove);
        setCheckedKeys(newCheckedKeys);
        setStates(prev => ({ ...prev, categories: newCheckedKeys }));

        onCheck(newCheckedKeys);
        setStates(prev => ({ ...prev, enableSubmit: false, categories: [], reviewChanges: 0 }));
    };

    const collapseAllGroups = () => {
        setExpandedKeys([]);
    };

    const clearAllChecked = () => {
        setCheckedKeys([]);
        setStates(prev => ({ ...prev, enableSubmit: false, categories: [], reviewChanges: 0 }));
    };

    const findNodeByKey = (nodes, key) => {
        for (const node of nodes) {
            if (node.key === key) {
                return node;
            }
            if (node.children) {
                const foundNode = findNodeByKey(node.children, key);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return null;
    };

    const onSearch = (e) => {
        const { value } = e.target;
        setSearchValue(value);

        if (!value) {
            setFilteredTreeData(treeData);
            setExpandedKeys([]);
            return;
        }

        const filteredKeys = [];
        const filterTree = (nodes) => {
            return nodes
                .map((node) => {
                    if (node.title.props.children[0].props.children.toLowerCase().includes(value.toLowerCase())) {
                        filteredKeys.push(node.key);
                        return { ...node };
                    }
                    if (node.children) {
                        const filteredChildren = filterTree(node.children);
                        if (filteredChildren.length > 0) {
                            filteredKeys.push(node.key);
                            return { ...node, children: filteredChildren };
                        }
                    }
                    return null;
                })
                .filter((node) => node !== null);
        };

        const filteredData = filterTree(treeData);
        setFilteredTreeData(filteredData);
        setExpandedKeys(filteredKeys);
        setStates(prev => ({ ...prev, reviewChanges: filteredKeys.length }));
    };

    return (
        <Spin spinning={isLoading}>
            <div className="category_wrapper product_category_page">
                <div className="categories_list_wrapper">
                    <div className="left_categories_wrapper">
                        <div className="category_header_sec">
                            <div className="top_sec">
                                <h3>Product Categories</h3>
                                <Button className="text_btn" onClick={collapseAllGroups}>
                                    Collapse All Groups
                                </Button>
                            </div>
                            <div className="search_box border_ui">
                                <Input
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={onSearch}
                                />
                            </div>
                        </div>
                        <Card className="category_card_wrap" bordered={false} bodyStyle={{ backgroundColor: '#f0f5ff' }}>
                            <Tree
                                checkable
                                checkStrictly // Enable strict checking to handle manually
                                onCheck={onCheck}
                                checkedKeys={checkedKeys}

                                treeData={filteredTreeData}
                                expandedKeys={expandedKeys}
                                onExpand={setExpandedKeys}
                            />
                        </Card>
                    </div>
                    <div className="divider"></div>

                    <div className="right_categories_wrapper">
                        <div className="category_header_sec">
                            <div className="top_sec">
                                <h3>Checked {checkedKeys?.length > 1 ? "Categories" : "Category"}</h3>
                                <Button className="text_btn" onClick={clearAllChecked}>
                                    Clear All Checked
                                </Button>
                            </div>
                        </div>
                        <Card className="category_card_wrap" bordered={false}>
                            <div>
                                {checkedKeys.map((key) => {
                                    const node = findNodeByKey(treeData, key);
                                    return (
                                        <div className="subcategory_card" key={key}>
                                            <div className="left_sec">
                                                <span className="category-name">{node?.data?.name_to_show}</span>
                                                <span className="category-path">{node?.data?.path}</span>
                                            </div>
                                            <div className="right_sec">
                                                <button
                                                    onClick={() => handleRemoveCategory(key)}
                                                    className="close_btn"
                                                >
                                                    <CloseCircleOutlined />
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ProductCategories;


