 export const filterOptions=[
    {
        "name":"dropdown",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
            {"name":"equal to"},
            {"name":"not equal to"},
            {"name":"contains any of"},
            {"name":"not contains any of"}
        ]
    },
     {
         "name":"multiselect",
         "options":[
             {"name":"is defined","notOptions":true},
             {"name":"is not defined","notOptions":true},
             {"name":"contains any of"},
             {"name":"not contains any of"}
         ]
     },
     {
         "name":"objectarray",
         "options":[
             {"name":"is defined","notOptions":true},
             {"name":"is not defined","notOptions":true},
             {"name":"contains any of"},
             {"name":"not contains any of"}
         ]
     },
    {
        "name":"boolean",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
            {"name":"equal to"},
            {"name":"not equal to"}
             ]
    },
    {
        "name":"decimal",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
            {"name":"equal to"},
            {"name":"not equal to"},
            {"name":"greater than"},
            {"name":"greater than or equals to"},
            {"name":"less than"},
            {"name":"less than or equals to"}
        ]
    },  {
        "name":"number",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
            {"name":"equal to"},
            {"name":"not equal to"},
            {"name":"greater than"},
            {"name":"greater than or equals to"},
            {"name":"less than"},
            {"name":"less than or equals to"}
        ]
    },
    {
        "name":"completeness",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
            {"name":"equal to"},
            {"name":"not equal to"},
            {"name":"greater than"},
            {"name":"greater than or equals to"},
            {"name":"less than"},
            {"name":"less than or equals to"}
        ]
    },
    {
        "name":"date",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
            {"name":"Custom Date","isNotOption":true,"values":[
                {"name":"equal to"},
                {"name":"after"},
                {"name":"on or after"},
                {"name":"before"},
                {"name":"on or before"}
            ]},
            {"name":"Relative Date","days":true}
        ]
    },
    {
        "name":"media",
        "options":[
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true}
        ]
    },
    {
        "name":"html",
        "options":[
           
            {"name":"is defined","notOptions":true},
            {"name":"is not defined","notOptions":true},
        ]
    },
     {
         "name":"text",
         "options":[

             {"name":"is defined","notOptions":true},
             {"name":"is not defined","notOptions":true},
             {"name":"equal to"},
             {"name":"not equal to"},
             {"name":"contains any of"},
             {"name":"not contains any of"},
             {"name":"Character Count","isNotOption":true,"checkBox":true,"values":[
                     {"name":"equal to"},
                     {"name":"not equal to"},
                     {"name":"greater than"},
                     {"name":"greater than or equals to"},
                     {"name":"less than"},
                     {"name":"less than or equals to"}
                 ]}
         ]
     },
     {
         "name":"url",
         "options":[

             {"name":"is defined","notOptions":true},
             {"name":"is not defined","notOptions":true},
             {"name":"equal to"},
             {"name":"not equal to"},
             {"name":"contains any of"},
             {"name":"not contains any of"},
             {"name":"Character Count","isNotOption":true,"checkBox":true,"values":[
                     {"name":"equal to"},
                     {"name":"not equal to"},
                     {"name":"greater than"},
                     {"name":"greater than or equals to"},
                     {"name":"less than"},
                     {"name":"less than or equals to"}
                 ]}
         ]
     }

    
]