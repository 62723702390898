import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../redux/thunk/UserThunk';
import {Link, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import { resetError, resetSubmitted } from "../../redux/slices/UserSlice";
import Toast from "../../components/Notification/Toast"
import AuthForm from "../../components/Form/AuthForm";
import "../../styles/Register.css"
import "../../styles/dashboard.scss"
import "../../styles/product_detail.scss"
import 'react-phone-input-2/lib/style.css'
import {REGISTER_FULFILLED} from "../../redux/types/UserActionTypes"
const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        // Dispatch the resetError action when the component mounts
        dispatch(resetError());
        dispatch(resetSubmitted());
    }, [dispatch]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {// return
            const payload = await dispatch(registerUser(values));
            if (payload.type === REGISTER_FULFILLED) {
                resetForm();
                Toast.successNotification('User Registered Successfully, Check your email for verification');
                dispatch(resetSubmitted());
                setSubmitting(false);
                setTimeout(() => {
                    navigate('/login');
                }, 2000);

            }else{
                Toast.errorNotification(payload.error.message);
                setSubmitting(false);
            }

        } catch (error) {
            // Handle errors
            console.error('An error occurred during form submission:', error);
            setSubmitting(false);
        }
    };

    const registrationFormFields = [
        { name: 'name', type: 'text', placeholder: 'Name' },
        { name: 'email', type: 'email', placeholder: 'Email' },
        { name: 'contactNumber', type: 'phone', placeholder: 'Enter phone number' },
        { name: 'companyName', type: 'text', placeholder: 'Company Name' },
    ];

    const registrationValidationSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        contactNumber: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid mobile number').required('Required'),
        companyName: Yup.string().required('Required'),
    });


    return (
        <div className="loginpage_wrapper register_page_wrap">
            <div className="login_bg"></div>
            <div className="inner-container">
                <div className="logo_wrap">PIM</div>
                <div className="whitebg_wrap register_wrap">
                    <h4>Register</h4>
                    <AuthForm
                        initialValues={{ name: '', email: '', contactNumber: '', companyName: '' }}
                        validationSchema={registrationValidationSchema}
                        onSubmit={handleSubmit}
                        fields={registrationFormFields}
                        buttonText="Register"
                    />
                </div>

                <p className="login-note" style={{ textAlign: 'center' }}>
                    Already have an account? <Link to="/login">Login</Link>
                </p>
            </div>
        </div>
    );
};

export default Register;
