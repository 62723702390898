import api from "../../config/api";

const AttributesService ={
    getAttributes: async () => {
        return await api.get('/attribute/list');
    },
    companyAssetUpload:async(formData)=>{
        return await api.post(`/company/asset/upload`, formData ,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    addAttribute: async (newAttribute) => {
        return await api.post('/attribute/add', {...newAttribute});
    },
    updateAttribute: async (attribute) => {
        return await api.put('/attribute/edit', {...attribute});
    },
    fetchAtrributeDetails: async (attributeId) => {
        return await api.get(`/attribute/details?attributeId=${attributeId}`);
    },
    deleteAttribute: async (attributeId) => {
        return await api.delete(`/attribute/delete?attributeId=${attributeId}`);
    },

    attributeGroupAdd: async (newAttributeGroup) => {
        return await api.post('/attribute/group/add', {...newAttributeGroup});
    },

    attributeGroupUpdate: async (attributeGroup) => {
        return await api.put('/attribute/group/edit', {...attributeGroup});
    },

    attrbuteGroupList: async () => {
        return await api.post('/attribute/group/list');
    },

    attributeGroupDelete: async (attributeGroupId) => {
        return await api.delete(`/attribute/group/delete?attributeGroupId=${attributeGroupId}`);
    }


};
export default AttributesService;