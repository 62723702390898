import { Checkbox, DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import CustomEditor from "./CustomEditor";


const {Option}=Select
export default function  FilterEditor({handleSetFilter, states, setStates}){
  useEffect(()=>{
    if(states.isEditable && states.editFilter){
      if(states.editFilter.match!==states.editFilter.type){
        setStates(prevVal=> ({...prevVal, checked: true}));
      }
      handleChange(states.editFilter.type);
        setStates(prevVal=> ({...prevVal, val: states.editFilter.value, selectedAttributeFilter:states.editFilter.value}));

        if(states.editFilter?.value || states.editFilter?.value===false || states.editFilter?.value===0){
          setStates(prevVal=> ({...prevVal, activeButton:true}));
        }
    }
    else {
        setStates(prevVal=> ({...prevVal, checked: false, val: null, activeButton: null, selectedOption: null, selectedAttributeFilter: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[states.isEditable,states.editFilter]);

  const handleChange=(value)=>{
      setStates(prevVal=> ({...prevVal, selectedOption: states.editFilter.match, val: states.editFilter.value}));
  }

  const handleChangeOptions=(value)=>{
      setStates(prevVal=> ({...prevVal, checkedValue: value}));
  if(!states.checked){
          setStates(prevVal=> ({...prevVal, checked: true}));
          let temp=states.filterVal.options.find(val=>val?.isNotOption);
          if(temp?.notOptions){
            setStates(prevVal=> ({...prevVal, activeButton:true}));
          } else {
              if(states.val  || states.val===false || states.val===0 ){
                setStates(prevVal=> ({...prevVal, activeButton:true}));
              }
              setStates(prevVal=> ({...prevVal, activeButton:false}));
          }
          setStates(prevVal=> ({...prevVal, val: null}));
      }
      else  setStates(prevVal=> ({...prevVal, checked: false}));
  }
  const handleSubmit=(value)=>{
   if(states.checked){
    let temp=states.filterVal.options.find(val=>val?.isNotOption);
     if(temp?.notOptions){
      setStates(prevVal=> ({...prevVal, activeButton:true}));
    } else {
         if(states.val || states.val===false || states.val===0){
          setStates(prevVal=> ({...prevVal, activeButton:true}));
         }
         setStates(prevVal=> ({...prevVal, activeButton:false}));
    }
    setStates(prevVal=> ({...prevVal, selectedOption: value}));
   } else {

    let temp=states.filterVal.options.find(val=>val.name===value);
    if(temp?.notOptions){
      setStates(prevVal=> ({...prevVal, activeButton:true}));
    } else {
      setStates(prevVal=> ({...prevVal, activeButton:false}));
    }
    setStates(prevVal=> ({...prevVal, selectedOption: value}));
   }
  }
  
return <div>
   <div>
   {
        states.filterVal?.options.map(val=>val?.isNotOption?<div className={"checkbox_card"}>
       <Checkbox onClick={()=>{
           setStates(prevVal=> ({...prevVal, selectedOption:null, val: null}));
        handleChangeOptions(val)
       }}
       checked={states.checked}
       /> { val.name}</div>:<></>)
    }
   </div>
   <div className={"form_group"}>
   <Select
    className={"attribute_select"}
    showSearch
    value={states.selectedOption}
    
    onSelect={(val)=>{
      handleSubmit(val);
    }}
    >
        {
           states.filterVal && states.filterVal?.options.map((val,index)=>states.checked? val?.isNotOption?val.values?.map((v,ind)=><Option key={ind} value={v.name}>{v.name}</Option>) :<></>:val?.isNotOption?<></>:<Option key={index} value={val.name}>{val.name}</Option>)
        }
    </Select>
   </div>
   <div className={"form_group"}>
    {
        states.selectedOption && ((states.selectedOption.name?states.selectedOption.name:states.selectedOption) !=='is defined' &&( states.selectedOption.name?states.selectedOption.name:states.selectedOption) !=='is not defined' ) && !states.selectedOption?.notOptions ? states.filterVal.name==='date'?
        states.selectedOption==='Relative Date' || states.selectedOption.name==="Relative Date"?
        <>
        Last <Input className={"custom-input-field"} type={'number'}   onChange={(val)=>handleSetFilter(val.target.value) }  value={states.val} style={{width:'50%'}}/> days
        </>
        :
        <DatePicker
        placeholder="Select Date"
        value={states.selectedAttributeFilter?isNaN(dayjs(states.selectedAttributeFilter))?null:dayjs(states.selectedAttributeFilter):null}
        onChange={(date, dateString) => handleSetFilter(dateString)}
        className={"custom_datepicker"}
        popupClassName={"custom_datepicker sidebar_dateui"}
    />:<CustomEditor className={"custom-input-field"} isFilter={true} onChange={handleSetFilter} options={states.selectedAttribute?.selectOption} value={states.val}  type={(states.filterVal.name==='dropdown' && ((states.selectedOption.name ? states.selectedOption.name : states.selectedOption)!=='equal to' && (states.selectedOption.name ? states.selectedOption.name : states.selectedOption)!=='not equal to'))?'multiselect':(states.filterVal.name==='html' ||states.filterVal.name==='url')?'text':states.filterVal.name}/>:<></>
    }
   </div>
    
    </div>
}
