import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {validateKey, resetPassword, accessTokenLoginUser} from '../../redux/thunk/UserThunk';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Button, Result, Spin} from 'antd';
import {FrownOutlined} from "@ant-design/icons";
import Toast from '../../components/Notification/Toast';
import {resetError} from "../../redux/slices/UserSlice";
import AuthForm from "../../components/Form/AuthForm";
import {RESET_PASSWORD_FULFILLED} from "../../redux/types/UserActionTypes"
const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const dispatch = useDispatch();
    const {isVerified, isPasswordSubmitted ,isLoading} = useSelector((state) => state.user);


    useEffect(() => {
        dispatch(resetError());
    }, [dispatch]);

    if (isVerified) {
        Toast.successNotification("Key Verified");
    }
    if (isVerified === false && isVerified !== null) {
        Toast.errorNotification("Key Invalid");
    }

    if (isPasswordSubmitted) {
        Toast.successNotification("Password Reset Successfully");
    }


    useEffect(() => {
        dispatch(validateKey(key));
    }, [dispatch, key]);

    const handlePasswordReset = async (values, {setSubmitting}) => {
        const obj = {
            key,
            password: values.newPassword,
        };
        const response = await dispatch(resetPassword(obj));
        if (response.type === RESET_PASSWORD_FULFILLED) {
            Toast.successNotification("Welcome to PIM");
            await dispatch(accessTokenLoginUser(response.payload));
            setTimeout(() => {
                navigate('/product/list');
            }, 1000);
        }
        setSubmitting(false);
    };

    const resetPasswordFormFields = [
        {name: 'newPassword', type: 'password', placeholder: 'New Password'},
        {name: 'confirmPassword', type: 'password', placeholder: 'Confirm Password'},
    ];

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required('Required').min(4),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Required'),
    });

    const handleMoveToLogin = () => {
        navigate('/login')
    }

    return (

            <div className="loginpage_wrapper register_page_wrap">
                <div className="login_bg"></div>
                <div className="inner-container">
                    <div className="logo_wrap">PIM</div>
                    <div className="whitebg_wrap register_wrap" style={{minWidth:'460px', minHeight:'306px'}}>
                        <Spin spinning={isLoading}>
                        <h4>Create New Password</h4>
                       {isVerified === true && (
                            <div>
                                <AuthForm
                                    initialValues={{newPassword: '', confirmPassword: ''}}
                                    validationSchema={validationSchema}
                                    onSubmit={handlePasswordReset}
                                    fields={resetPasswordFormFields}
                                    buttonText="Reset Password"
                                />
                            </div>)}

                        {isVerified === false && (
                            <div style={{height: '100vh'}}>
                                <Result
                                    icon={<FrownOutlined/>}
                                    title="Invalid Key"
                                    subTitle="The provided key is invalid. Please check and try again."
                                    extra={<Button onClick={handleMoveToLogin}>Go to Login</Button>}
                                />
                            </div>)}

        </Spin>
                    </div>
                </div>
            </div>


    );
};

export default ResetPassword;
