import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Tree, Card, Typography, Space, Button, Modal, Input, Spin, Popconfirm, List} from 'antd';
import CategoryThunk from '../../../redux/thunk/CategoryThunk';
import Toast from '../../../components/Notification/Toast';
import {DeleteOutlined} from "@ant-design/icons";
import {CATEGORY_DETAILS_FULFILLED,SUB_CATEGORY_ADD_FULFILLED,CATEGORY_ADD_FULFILLED,CATEGORY_DELETE_FULFILLED} from "../../../redux/types/CategoryActionTypes";

const {Text} = Typography;

const Categories = () => {
    const dispatch = useDispatch();
    const {categories, isLoading} = useSelector((state) => state.categories);
    const [treeData, setTreeData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryModalVisible, setSubCategoryModalVisible] = useState(false);
    const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false);
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);

    useEffect(() => {
        dispatch(CategoryThunk.categoryList());
    }, [dispatch]);

    const createTreeNode = useCallback((category) => {
        const hasChildren = category?.children && category?.children > 0;

        return {
            key: category?.id,
            title: (
                <Space>
                    <Text>{category?.nameToShow}</Text>

                    <span>
                        {


                            !!category?.children && <Text
                                type="secondary">({category?.children} {category?.children > 1 ? "subcategories" : "subcategory"})</Text>

                        }
                    </span>
                </Space>
            ),
            data: category,
            children: hasChildren ? [] : null,
        };
    }, []);

    useEffect(() => {
        if (categories.length > 0) {

            const initialTreeData = categories.map(createTreeNode);
            setTreeData(initialTreeData);
        }
    }, [categories, createTreeNode]);


    const fetchSubcategories = useCallback(async (parentNode) => {
        const {key} = parentNode;
        if(!parentNode?.children){
            // if children not exist dont make api call create empty children
            const updatedNode = {...parentNode, children: []};
            setSelectedSubCategory([]);
            return updatedNode;

        }
        const payload = await dispatch(CategoryThunk.categoryDetails(key));

        if (payload.type === CATEGORY_DETAILS_FULFILLED) {
            setSelectedSubCategory(payload?.payload?.data?.subCategoryList);
            const subcategories = payload?.payload?.data?.subCategoryList.map(createTreeNode);
            const updatedNode = {...parentNode, children: subcategories};
            return updatedNode;
        }

        return parentNode;
    }, [dispatch, createTreeNode]);

    const handleNodeClick = async (selectedKeys, {node}) => {
        const selectedKey = node.key;
        let parentNode = treeData.find((n) => n.key === selectedKey);

        if (!parentNode) {
            treeData.forEach((n) => {
                if (n.children) {
                    const childNode = findNodeInChildren(n.children, selectedKey);
                    if (childNode) {
                        parentNode = childNode;
                    }
                }
            });
        }
        const updatedNode = await fetchSubcategories(parentNode);
        setSelectedCategory(updatedNode.data);
        setTreeData((prevData) => updateTreeData(prevData, updatedNode));
        setExpandedKeys([...expandedKeys, selectedKey]);
    };

    const findNodeInChildren = useCallback((children, targetKey) => {
        for (const child of children) {
            if (child.key === targetKey) {
                return child;
            }
            if (child.children) {
                const foundInChildren = findNodeInChildren(child.children, targetKey);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }
        return null;
    }, []);

    const updateTreeData = useCallback((tree, updatedNode) => {
        return tree.map((node) => {
            if (node.key === updatedNode.key) {
                return updatedNode;
            }
            if (node.children) {
                const updatedChildren = updateTreeData(node.children, updatedNode);
                if (updatedChildren !== node.children) {
                    return {
                        ...node,
                        children: updatedChildren,
                    };
                }
            }
            return node;
        });
    }, []);


    const AddSubcategory = async () => {
        if (!selectedCategory) {
            return Toast.errorNotification('Please select a category');
        }
        if (!subCategoryName) {
            return Toast.errorNotification('Please enter a subcategory name');
        }

        const body = {
            name: subCategoryName,
            categoryId: selectedCategory.id,
        };

        const payload = await dispatch(CategoryThunk.subCategoryAdd(body));

        if (payload.type === SUB_CATEGORY_ADD_FULFILLED) {
            const newSubCategoryData = payload.payload.data.subCategoryData;
            let parentNode = treeData.find((n) => n.key === selectedCategory.id);
            if (!parentNode) {
                treeData.forEach((n) => {
                    if (n.children) {
                        const childNode = findNodeInChildren(n.children, selectedCategory.id);
                        if (childNode) {
                            parentNode = childNode;
                        }
                    }
                });
            }

            const updatedTreeData = updateTreeData(treeData, {
                ...parentNode,
                children: [...(parentNode.children || []), createTreeNode(newSubCategoryData)],
                title: (
                    <Space>
                        <Text>{parentNode?.data?.nameToShow}</Text>

                        {
                            <Text type="secondary">
                                {((parentNode?.children?.length || 0) + 1) > 0
                                    ? `(${(parentNode.children.length || 0) + 1} ${(parentNode.children.length || 0) + 1 === 1 ? 'subcategory' : 'subcategories'})`
                                    : null
                                }
                            </Text>


                        }
                    </Space>
                ),
            });
            setTreeData(updatedTreeData);
            setExpandedKeys([...expandedKeys, newSubCategoryData.id]);
            setSubCategoryModalVisible(false);
            setSubCategoryName('');
            setSelectedSubCategory([...selectedSubCategory, newSubCategoryData]);
            // update children count in parent node of selected category
            setSelectedCategory({...selectedCategory, children: selectedCategory.children + 1})


        } else {
            Toast.errorNotification(payload?.error?.message);
        }
    };

    const AddCategory = async () => {
        if (!subCategoryName) {
            return Toast.errorNotification('Please enter a category name');
        }

        const body = {
            name: subCategoryName,
        };

        const payload = await dispatch(CategoryThunk.categoryAdd(body));

        if (payload.type === CATEGORY_ADD_FULFILLED) {
            const newCategoryData = payload.payload.data.categoryData;
            const updatedTreeData = [...treeData, createTreeNode(newCategoryData)];
            setTreeData(updatedTreeData);

            setAddCategoryModalVisible(false);
            setSubCategoryName('');
        } else {
            Toast.errorNotification(payload?.error?.message);
        }
    };

    const DeleteCategory = async (selectedCategory) => {
        if (!selectedCategory) {
            return Toast.errorNotification('Please select a category');
        }
        const findParent = findParentNode(treeData, selectedCategory.id);
        const payload = await dispatch(CategoryThunk.categoryDelete(selectedCategory.id));
        if (payload.type === CATEGORY_DELETE_FULFILLED) {
            let parentNode = treeData.find((n) => n.key === selectedCategory.id);
            if (!parentNode) {
                treeData.forEach((n) => {
                    if (n.children) {
                        const childNode = findNodeInChildren(n.children, selectedCategory.id);
                        if (childNode) {
                            parentNode = childNode;
                        }
                    }
                });
            }

            const updatedTreeData = deleteNodeFromTree(treeData, selectedCategory.id);
            setTreeData(updatedTreeData);
            if (findParent) {
                const updatedParent = {
                    ...findParent,
                    children: findParent.children.filter((item) => item.key !== selectedCategory.id),
                    title: (
                        <Space>
                            <Text>{findParent?.data?.nameToShow}</Text>

                            {
                                <Text type="secondary">
                                    {findParent?.children?.length > 0
                                        ? `(${findParent?.children?.length} ${findParent?.children?.length === 1 ? 'subcategory' : 'subcategories'})`
                                        : null
                                    }
                                </Text>


                            }
                        </Space>
                    ),
                };
                const updatedTreeData = updateTreeData(treeData, updatedParent);
                setTreeData(updatedTreeData);
            }

            setAddCategoryModalVisible(false);
          // // if there is no children then delete from selectedSubCategory
          //   if(!findParent?.children){
          //       setSelectedCategory(null);
          //   }
            // if its a root category then delete from selectedSubCategory
            if(findParent === null ){
                setSelectedSubCategory([])
            }else

            {
                setSelectedSubCategory(selectedSubCategory.filter((item) => item.id !== selectedCategory.id))
            }
            setSelectedCategory(findParent === null ? null : findParent?.data )


            setSubCategoryName('');
        }
    };

    const findParentNode = useCallback((tree, childKey) => {
        for (const node of tree) {
            if (node.children) {
                const foundInChildren = node.children.find(child => child.key === childKey);
                if (foundInChildren) {
                    return node;
                } else {
                    const foundInChildNode = findParentNode(node.children, childKey);
                    if (foundInChildNode) {
                        return foundInChildNode;
                    }
                }
            }
        }
        return null;
    }, []);


    const deleteNodeFromTree = (tree, nodeId) => {
        return tree.filter((node) => {
            if (node.key === nodeId) {
                // Node found, exclude it
                return false;
            } else if (node.children) {
                // Node has children, recursively delete from children
                node.children = deleteNodeFromTree(node.children, nodeId);
                return true;
            }
            // Node doesn't match the given nodeId
            return true;
        });
    };


    const [checkedKeys, setCheckedKeys] = useState(["65a24f7eea136cc78640a40d"]);

    const onCheck = (checkedKeys, info) => {
        setCheckedKeys(checkedKeys);
    };

    const handleExpand = (keys, expanded, node) => {
        if (expanded) {
            setExpandedKeys([...expandedKeys, node.key]);
        } else {
            setExpandedKeys(expandedKeys.filter(key => key !== node.key));
        }
    };
    const handleDeleteCategory = async (category) => {
        DeleteCategory(category);

    }


    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Spin spinning={isLoading}>
            <div className={"category_wrapper"}>
                <Modal
                    title="Add Subcategory"
                    open={subCategoryModalVisible}
                    onCancel={() => {
                        setSubCategoryName('');
                        setSubCategoryModalVisible(false);
                    }}

                    className={"custom_modal category_modal"}
                    footer={[
                        <Button
                            onClick={() => {
                                setSubCategoryName('');
                                setSubCategoryModalVisible(false);
                            }}
                            className={"secondary_btn"} key="1">Cancel</Button>,
                        <Button className={"primary_btn"}
                                onClick={AddSubcategory}
                                key="2">Add</Button>
                    ]}
                >
                    <Input
                        type="text"
                        value={subCategoryName}
                        onChange={(e) => setSubCategoryName(e.target.value)}
                        placeholder="Subcategory Name"
                        rootClassName={"custom-input-field"}
                    />
                </Modal>

                <Modal
                    title="Add Category"
                    open={addCategoryModalVisible}
                    className={"custom_modal category_modal"}
                    onCancel={() => {
                        setSubCategoryName('');
                        setAddCategoryModalVisible(false);
                    }}
                    footer={[
                        <Button
                            onClick={() => {
                                setSubCategoryName('');
                                setAddCategoryModalVisible(false);
                            }}
                            className={"secondary_btn"} key="1">Cancel</Button>,
                        <Button className={"primary_btn"}
                                onClick={AddCategory}
                                key="2">Add</Button>
                    ]}

                >
                    <Input
                        type="text"
                        value={subCategoryName}
                        onChange={(e) => setSubCategoryName(e.target.value)}
                        placeholder="Category Name"
                        rootClassName={"custom-input-field"}
                    />
                </Modal>

                <div className={"categories_list_wrapper"}>
                    <div className={"left_categories_wrapper"}>
                        <div className={"category_header_sec"}>
                            <div className={"top_sec"}>
                                <h3>Product Categories</h3>
                                <div className={"right_sec"}>
                                    {

                                        selectedCategory && selectedCategory?.parentsIds?.length === 0   && (


                                            <Button
                                                className={"secondary_icon_btn"}
                                                type="secondary"
                                                onClick={showModal}

                                            >
                                                {/*{JSON.stringify(selectedCategory)}*/}
                                                {/*<Popconfirm*/}
                                                {/*    title="Are you sure to delete this category?"*/}
                                                {/*    onConfirm={() => DeleteCategory(selectedCategory)}*/}
                                                {/*    okText="Yes"*/}
                                                {/*    cancelText="No"*/}
                                                {/*    rootClassName={"custom_popover_wraper"}*/}
                                                {/*>*/}
                                                {/*    */}
                                                {/*</Popconfirm>*/}
                                                <DeleteOutlined/>
                                                Delete
                                            </Button>
                                        )
                                    }
                                    <Button
                                        className={"primary_btn"}
                                        onClick={() => {
                                            setSubCategoryName('');
                                            setAddCategoryModalVisible(true);
                                        }}
                                    >
                                        <i className={"icon"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <path
                                                    d="M2.91669 6.99999H7.00002M7.00002 6.99999H11.0834M7.00002 6.99999V2.91666M7.00002 6.99999V11.0833"
                                                    stroke="white" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                        </i> Add Category
                                    </Button>
                                </div>
                            </div>

                            <div className="search_box border_ui">
                                <input type="text"
                                       placeholder="Search"
                                />
                                <span className="search_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                          <path
                                              d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                                              stroke="#969696" strokeWidth="1.6" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                            </div>
                        </div>

                        <Card className={"category_card_wrap"} title="">
                            <Tree
                                treeData={treeData}
                                checkedKeys={checkedKeys}
                                onCheck={onCheck}
                                expandedKeys={expandedKeys}
                                onSelect={handleNodeClick}
                                onExpand={(keys, {expanded, node}) => handleExpand(keys, expanded, node)}
                            />
                        </Card>

                        <Modal title="Are you Sure"
                               open={isModalOpen}
                               onCancel={handleCancel}
                                className={"custom_modal w-500 delete_modal_wrap"}
                               footer={[
                                   <Button className={"modal_secondary_btn secondary_btn"}
                                           onClick={handleCancel}
                                           key="1">Cancel</Button>,
                                   <Button className={"primary_btn delete_btn"}
                                           onClick={()=> {
                                               DeleteCategory(selectedCategory)
                                               setIsModalOpen(false)
                                           }}
                                           key="2">Delete</Button>,
                               ]}
                        >
                            <div className={"modal_content"}>
                                <h5>Are you sure you want to delete this categories?</h5>
                                <div className={"warning_message"}>
                                    <label>
                                        <i className={"icon"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                                                <path d="M0 19L11 0L22 19H0ZM11 16C11.2833 16 11.521 15.904 11.713 15.712C11.905 15.52 12.0007 15.2827 12 15C12 14.7167 11.904 14.4793 11.712 14.288C11.52 14.0967 11.2827 14.0007 11 14C10.7167 14 10.4793 14.096 10.288 14.288C10.0967 14.48 10.0007 14.7173 10 15C10 15.2833 10.096 15.521 10.288 15.713C10.48 15.905 10.7173 16.0007 11 16ZM10 13H12V8H10V13Z" fill="#E46A11"/>
                                            </svg>
                                        </i>
                                        Warning
                                    </label>
                                    <p>By deleting this categories <a href={"javascript:;"}>{selectedCategory?.children} subcategories</a>  will also be permentantly deleted</p>
                                </div>
                            </div>
                        </Modal>

                    </div>
                    <div className={"divider"}></div>
                    <div className={"right_categories_wrapper"}>
                        <div className={"category_header_sec"}>
                            <div className={"top_sec"}>
                                <h3>Selected {selectedSubCategory?.length > 1 ? "Categories" : "Category"}</h3>


                                <div className={"right_sec"}>





                                    {selectedCategory && (
                                        <>
                                            <Button
                                                className={"primary_btn"}
                                                onClick={() => {
                                                    setSubCategoryModalVisible(true);
                                                }}
                                                type="primary"

                                            >
                                                <i className={"icon"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                         viewBox="0 0 14 14" fill="none">
                                                        <path
                                                            d="M2.91669 6.99999H7.00002M7.00002 6.99999H11.0834M7.00002 6.99999V2.91666M7.00002 6.99999V11.0833"
                                                            stroke="white" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                    </svg>
                                                </i> Add Subcategory
                                            </Button>


                                        </>
                                    )}
                                </div>

                            </div>

                            <div className={"category_path"}>
                                  <span
                                      className={"selected_category"}
                                  >
                                        {selectedCategory?.path}

                                    </span>
                            </div>

                            <div className="search_box border_ui">
                                <input type="text"
                                       placeholder="Search"
                                />
                                <span className="search_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                          <path
                                              d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                                              stroke="#969696" strokeWidth="1.6" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                            </div>
                        </div>


                        {selectedSubCategory && (
                            <Card className={"category_card_wrap"} title="">
                                <List
                                    dataSource={selectedSubCategory}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            className={"subcategory_card"}
                                            style={{padding: '8px 10px 8px 20px'}}
                                            key={index}
                                            actions={[
                                                <Button
                                                    type="danger"
                                                    onClick={() => item.children?{}:handleDeleteCategory(item)}
                                                >
                                                    { !!item?.children && <Popconfirm
                                                        title="Are you sure to delete this  Subcategory?"
                                                        onConfirm={() => handleDeleteCategory(item)
                                                        }
                                                        okText="Yes"
                                                        cancelText="No"
                                                        rootClassName={"custom_popover_wraper"}
                                                    >
                                                        <DeleteOutlined/>
                                                    </Popconfirm>}
                                                    {!item.children && <DeleteOutlined/>}
                                                </Button>
                                            ]}
                                        >
                                            <List.Item.Meta title={item?.name}/>
                                            <List.Item.Meta title={item?.path}/>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        )}




                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default Categories;

